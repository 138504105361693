<h2>Registrierung</h2>
<form (ngSubmit)="register()" [formGroup]="formGroup">
  <div class="mb-3">
    <label class="form-label" for="username">Benutzername</label>
    <input tabindex="1" [ngClass]="{'error': Tools.checkFormControl(formGroup.controls.username)}" autocomplete="username" class="form-control" formControlName="username" id="username" placeholder="Kevin" type="text">
    <span *ngIf="Tools.checkFormControl(formGroup.controls.username, 'required')" class="error d-block"> wurde nicht ausgefüllt</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.username, 'minlength')" class="error d-block"> muss mindestens {{formGroup.controls.username.getError('minlength')['requiredLength']}} Zeichen lang sein</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.username, 'maxlength')" class="error d-block"> darf maximal {{formGroup.controls.username.getError('maxlength')['requiredLength']}} Zeichen lang sein</span>
  </div>
  <div class="mb-3">
    <label class="form-label" for="password">Passwort</label>
    <div class="d-flex">
      <input tabindex="2" [ngClass]="{'error': Tools.checkFormControl(formGroup.controls.password)}" autocomplete="new-password" class="form-control d-inline" formControlName="password" id="password" placeholder="***********" type="password">
      <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
    </div>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password, 'required')" class="error d-block"> wurde nicht ausgefüllt</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password, 'contains')" class="error d-block"> darf den Vor- und Nachnamen nicht enthalten</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password, 'minlength')" class="error d-block"> muss mindestens {{formGroup.controls.password.getError('minlength')['requiredLength']}} Zeichen lang sein</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password, 'password')" class="error d-block"> entspricht nicht den Kennwortrichtlinien</span>
  </div>
  <div class="mb-3">
    <label class="form-label" for="password_repeat">Passwort wiederholen</label>
    <div class="d-flex">
      <input tabindex="3" [ngClass]="{'error': Tools.checkFormControl(formGroup.controls.password_repeat)}" autocomplete="new-password" class="form-control d-inline" formControlName="password_repeat" id="password_repeat" placeholder="***********"
             type="password">
      <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
    </div>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password_repeat, 'required')" class="error d-block"> wurde nicht ausgefüllt</span>
    <span *ngIf="Tools.checkFormControl(formGroup.controls.password_repeat, 'matching')" class="error d-block"> passwörter stimmen nicht überein</span>
  </div>
  <button [disabled]="!formGroup.valid" class="btn btn-primary" type="submit">Registrieren</button>
</form>
