<nav class="navbar navbar-expand-lg z-1 w-100 top-0">
  <div class="container-fluid">
    <a tabindex="-1" class="navbar-brand" routerLink="home">{{Tools.pageName}}</a>
    <button #toggler aria-controls="navbarBody" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler shadow-none" data-bs-target="#navbarBody" data-bs-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarBody" (click)="onNavClick($event)">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!--<li class="nav-item" *ngIf="admin">
          <a class="nav-link" routerLink="admin">Admin Center</a>
        </li>-->
      </ul>
      <ul class="navbar-nav ml-auto">
        <li *ngIf="authService.isLoggedIn else loggedOut" class="nav-item dropdown">
          <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
            {{authService.getUsername()}}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a (click)="this.authService.logout()" class="dropdown-item">Abmelden</a></li>
          </ul>
        </li>
        <ng-template #loggedOut>
          <li class="nav-item">
            <a tabindex="0" class="nav-link" routerLink="/auth/login">Anmelden</a>
          </li>
          <li class="nav-item">
            <a tabindex="0" class="nav-link" routerLink="/auth/register">Registrieren</a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</nav>

<div class="container-sm mt-6 p-4 rounded shadow-sm">
  <router-outlet></router-outlet>
</div>
