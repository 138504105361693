import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../assets/js/service/auth";
import {Tools} from "../../../assets/js/tools";
import {NetworkService} from "../../../assets/js/service/network";
import {db} from "../../../db/db";
import {User} from "../../../assets/js/model/User";

@Component({
  selector: 'app-decrypt',
  templateUrl: './decrypt.component.html',
  styleUrls: ['./decrypt.component.scss']
})
export class DecryptComponent {
  protected updated: boolean = false;

  formGroup = new FormGroup({
    username: new FormControl(""),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(14)
    ])
  });
  protected readonly Tools = Tools;

  constructor(private authService: AuthService, networkService: NetworkService) {
    networkService.request("GET", "/auth/account").then(async response => {
      const user = response.data as User;

      this.authService.setUser(user);

      await db.connection.remove({
        from: "users",
        where: {
          user_id: user.user_id
        }
      });

      await db.connection.insert({
        into: "users",
        values: [response.data as User]
      })

      this.updated = true;
    });
  }

  decrypt() {
    this.authService.decrypt(this.formGroup);
  }
}
