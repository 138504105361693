import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ChatService} from "../../../assets/js/service/chat";
import {FileInfo} from "../../../assets/js/model/FileInfo";
import {ScreenSizeService} from "../../../assets/js/service/screen";
import {FileViewerService} from "../../../assets/js/service/fileviewer";
import {FileUtils} from "../../../assets/js/file_utils";

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrl: './file-viewer.component.scss'
})
export class FileViewerComponent implements AfterViewInit, OnDestroy {
  private initialX: number  = 0;
  private initialY: number = 0;
  private initialXSave: number = 0;
  private initialYSave: number = 0;
  private initialDistance: number = 0;
  private initialScale: number = 1;
  private currentScale: number = 1;
  private currentTranslateX: number = 0;
  private currentTranslateY: number = 0;
  private lastTranslateX: number = 0;
  private lastTranslateY: number = 0;

  private doubleTapTimeout: any = null;

  private intersectionObserver?: IntersectionObserver;

  public isVisible: boolean = false;

  @ViewChild("fileViewer", {static: false})
  private elRef!: ElementRef;

  constructor(protected chatService: ChatService, protected fileViewerService: FileViewerService, protected screenSizeService: ScreenSizeService, private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  private getDistance(touches: any): number {
    let dx = touches[0].clientX - touches[1].clientX;
    let dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  }

  // Handle double tap or double click for zooming in and out
  private handleDoubleTap(target: HTMLElement, pageX: number, pageY: number) {
    clearTimeout(this.doubleTapTimeout);
    this.doubleTapTimeout = null;

    // Get the bounding rectangle of the target element
    const rect = target.getBoundingClientRect();

    // Calculate element's position relative to the page
    const elemTop = rect.top + window.scrollY;
    const elemLeft = rect.left + window.scrollX;
    const elemBottom = rect.bottom + window.scrollY;
    const elemRight = rect.right + window.scrollX;

    let relativeX: number;
    let relativeY: number;

    // Check if the pageX and pageY are inside the element
    if (pageX >= elemLeft && pageX <= elemRight && pageY >= elemTop && pageY <= elemBottom) {
      // Calculate the relative position inside the element
      relativeX = pageX - elemLeft; // X position relative to the element
      relativeY = pageY - elemTop;  // Y position relative to the element
    } else {
      // Use the center of the element
      relativeX = rect.width / 2;
      relativeY = rect.height / 2;
    }

    if (this.currentScale === 1) {
      // Zoom in on double-tap
      const newScale = 2;  // Adjust the zoom level as per your preference
      const scaleFactor = newScale / this.currentScale; // Which is 2

      // Adjust the translation to keep the double-tapped point at the same position
      this.currentTranslateX = relativeX - ((relativeX - this.currentTranslateX) * scaleFactor);
      this.currentTranslateY = relativeY - ((relativeY - this.currentTranslateY) * scaleFactor);

      this.currentScale = newScale;
    } else {
      // Zoom out on double-tap
      this.currentScale = 1;
      this.currentTranslateX = 0;
      this.currentTranslateY = 0;
    }

    // Apply scaling and translation
    target.style.transform = `translate(${this.currentTranslateX}px, ${this.currentTranslateY}px) scale(${this.currentScale})`;
    target.style.transformOrigin = 'top left'; // Ensure scaling from the top-left corner
  }

  onImageClick(evt: MouseEvent): void {
    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      return;
    }

    if (this.doubleTapTimeout !== null) {
      this.handleDoubleTap(evt.target as HTMLElement, evt.clientX, evt.clientY);
    } else {
      this.doubleTapTimeout = setTimeout(() => {
        this.doubleTapTimeout = null;
        // Handle single click or other click logic here if needed
      }, 300);  // 300ms window for double-click detection
    }
  }

  onImageTouch(evt: TouchEvent): void {
    evt.preventDefault();

    if (evt.touches.length === 1) {
      if (this.doubleTapTimeout !== null) {
        this.initialX = this.initialXSave;
        this.initialY = this.initialYSave;
        this.handleDoubleTap(evt.target as HTMLElement, evt.touches[0].clientX, evt.touches[0].clientY);
      } else {
        this.initialXSave = this.initialX;
        this.initialYSave = this.initialY;

        this.initialX = evt.touches[0].clientX - this.lastTranslateX;
        this.initialY = evt.touches[0].clientY - this.lastTranslateY;

        this.doubleTapTimeout = setTimeout(() => {
          this.doubleTapTimeout = null;
        }, 300);  // 300ms window for double-tap detection
      }
    } else if (evt.touches.length === 2) {
      // Pinch-to-zoom
      this.initialDistance = this.getDistance(evt.touches);
      this.initialScale = this.currentScale;
    }
  }

  onImageMove(evt: TouchEvent): void {
    evt.preventDefault();

    const target = evt.target as HTMLElement;

    if (this.currentScale > 1 && evt.touches.length === 1) {
      // Panning (dragging)

      // Calculate new translation values
      const newX = evt.touches[0].clientX - this.initialX;
      const newY = evt.touches[0].clientY - this.initialY;

      this.currentTranslateX = newX;
      this.currentTranslateY = newY;

    } else if (evt.touches.length === 2) {
      // Zooming
      const newDistance = this.getDistance(evt.touches);
      const scaleChange = newDistance / this.initialDistance;
      const newScale = this.initialScale * scaleChange;

      if (newScale >= 1) {
        this.currentScale = newScale;
      }
    }

    // Get dimensions
    const imageWidth = target.offsetWidth;
    const imageHeight = target.offsetHeight;

    const parent = target.parentElement;
    if (!parent) return;
    const parentWidth = parent.clientWidth;
    const parentHeight = parent.clientHeight;

    // Calculate scaled image dimensions
    const scaledWidth = imageWidth * this.currentScale;
    const scaledHeight = imageHeight * this.currentScale;

    // Calculate constraints for X-axis
    if (scaledWidth >= parentWidth) {
      // Image is larger than parent; can pan within limits
      const minTranslateX = parentWidth - scaledWidth;
      const maxTranslateX = 0;

      // Clamp the translation values
      this.currentTranslateX = Math.max(minTranslateX, Math.min(this.currentTranslateX, maxTranslateX));
    } else {
      // Image is smaller than parent; center it
      this.currentTranslateX = (parentWidth - scaledWidth) / 2;
    }

    // Calculate constraints for Y-axis
    if (scaledHeight >= parentHeight) {
      // Image is larger than parent; can pan within limits
      const minTranslateY = parentHeight - scaledHeight;
      const maxTranslateY = 0;

      // Clamp the translation values
      this.currentTranslateY = Math.max(minTranslateY, Math.min(this.currentTranslateY, maxTranslateY));
    } else {
      // Image is smaller than parent; center it
      this.currentTranslateY = (parentHeight - scaledHeight) / 2;
    }

    // Apply both scaling and translation
    target.style.transform = `translate(${this.currentTranslateX}px, ${this.currentTranslateY}px) scale(${this.currentScale})`;
    target.style.transformOrigin = 'top left'; // Ensure scaling from the top-left corner
  }

  onImageEnd(): void {
    // Save the current translation values for the next pan
    this.lastTranslateX = this.currentTranslateX;
    this.lastTranslateY = this.currentTranslateY;
  }

  protected previousFile(event: boolean = false): void {
    if (event && this.currentScale !== 1) {
      return;
    }

    if (this.chatService.open_message?.files.length === 1) {
      return;
    }

    const currentIndex = this.chatService.open_message?.files.findIndex((file: FileInfo) => file === this.fileViewerService.getFile());
    this.fileViewerService.setFile(this.findPreviousFile(currentIndex, this.chatService.open_message!.files));
  }

  protected findPreviousFile(currentIndex: number, files: FileInfo[]): FileInfo {
    if (currentIndex === 0) {
      for (let i = files.length - 1; i >= 0; i--) {
        if (FileUtils.media_types.includes(files[i].type)) {
          return files[i];
        }
      }
    }

    for (let i = currentIndex - 1; i >= 0; i--) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    return files[currentIndex];
  }

  protected nextFile(event: boolean = false): void {
    if (event && this.currentScale !== 1) {
      return;
    }

    if (this.chatService.open_message?.files.length === 1) {
      return;
    }

    const currentIndex = this.chatService.open_message?.files.findIndex((file: FileInfo) => file === this.fileViewerService.getFile());
    this.fileViewerService.setFile(this.findNextFile(currentIndex, this.chatService.open_message!.files));
  }

  protected findNextFile(currentIndex: number, files: FileInfo[]): FileInfo {
    if (currentIndex === files.length - 1) {
      for (let i = 0; i < files.length; i++) {
        if (FileUtils.media_types.includes(files[i].type)) {
          return files[i];
        }
      }
    }

    for (let i = currentIndex + 1; i < files.length; i++) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    for (let i = 0; i < files.length; i++) {
      if (FileUtils.media_types.includes(files[i].type)) {
        return files[i];
      }
    }

    return files[currentIndex];
  }

  protected closeFile(event: boolean = false): void {
    if (event && this.currentScale !== 1) {
      return;
    }

    this.fileViewerService.removeFile();

    this.initialDistance = 0;
    this.initialX = 0;
    this.initialY = 0;
  }

  private initIntersectionObserver(): void {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.elRef.nativeElement) {
          this.isVisible = entry.isIntersecting;
          this.cdRef.detectChanges();
        }
      });
    }, {threshold: [0.1]});

    if (this.elRef && this.elRef.nativeElement) {
      this.intersectionObserver.observe(this.elRef.nativeElement);
    }
  }

  protected readonly FileUtils = FileUtils;
}
