import {SwPush} from "@angular/service-worker";
import {Injectable} from "@angular/core";
import {CryptUtils} from "../crypt_utils";
import {NetworkService} from "./network";

const UAParser = require("ua-parser-js");

@Injectable({
  providedIn: "root"
})
export class SubscriptionService {
  private readonly VAPID_PUBLIC_KEY: string = "BHaU-NAqKs1k5yCad2t4P_7R9HwdXuPlp0iETtLjpMb50pg_mlEqVBptYH8mgkKEHt7gGbvxSNojcG1s2UElOGg";

  constructor(private swPush: SwPush, private networkService: NetworkService) {
  }

  subscribe(): void {
    if (this.swPush.isEnabled) {
        const user = localStorage.getItem("user");

        if (user !== null) {
          setTimeout(() => {
            this.subscribeToNotifications();
          }, 500);
        }
    }
  }

  private async subscribeToNotifications(): Promise<void> {
    const subscription = JSON.parse(JSON.stringify(await this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })));

    if (!subscription) {
      return;
    }

    subscription.device_id = await CryptUtils.hashString(this.getDeviceInfo());

    await this.networkService.request("POST", "/subscription", JSON.stringify(subscription));
  }

  private getDeviceInfo(): string {
    const parser = new UAParser(navigator.userAgent);

    return JSON.stringify({
      vendor: parser.getDevice().vendor, // Device Vendor
      isMobile: parser.getDevice().type === "mobile", // Boolean indicating if the device is a mobile device
      browser: parser.getBrowser().name, // Browser
      os: parser.getOS().name // Operating system
    });
  }
}
