import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../assets/js/validators";
import {AuthService} from "../../../assets/js/service/auth";
import {Tools} from "../../../assets/js/tools";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  formGroup = new FormGroup(
    {
      username: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(32)
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(14),
        CustomValidators.password({"password": true})
      ]),
      password_repeat: new FormControl("", [
        Validators.required,
      ]),
    },
    {
      validators: [
        CustomValidators.match("password", "password_repeat"),
        CustomValidators.contains("password", "first_name"),
        CustomValidators.contains("password", "last_name")
      ],
      updateOn: "change"
    }
  );
  protected readonly Tools = Tools;

  constructor(private authService: AuthService) {
  }

  register() {
    this.authService.register(this.formGroup);
  }
}
