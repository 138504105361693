<h2>Anmeldung</h2>
<form (ngSubmit)="login()" [formGroup]="formGroup">
  <div class="mb-3">
    <label class="form-label" for="username">Benutzername</label>
    <input tabindex="1" autocomplete="username" class="form-control" formControlName="username" id="username" placeholder="Kevin" type="text" autofocus>
  </div>
  <div class="mb-3">
    <label class="form-label" for="password">Passwort</label>
    <div class="d-flex">
      <input tabindex="2" autocomplete="password" class="form-control d-inline" formControlName="password" id="password" placeholder="***********" type="password">
      <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
    </div>
  </div>
  <a class="d-block text-decoration-none float-end" href="/auth/register">Noch kein Konto?</a>
  <button [disabled]="!formGroup.valid" class="btn btn-primary" type="submit">Anmelden</button>
</form>
