import {Injectable} from "@angular/core";
import {URLSearchParamsPlus} from "../url_searchparams";
import {Tools} from "../tools";
import {BehaviorSubject} from "rxjs";

type Response = {
  message: string,
  status: string,
  code: number,
  data: any,
  error: any,
  errorData: any
};

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public logout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private API_HOST: string = window.location.origin + "/api";

  private requesting: boolean = false;
  private requesting_callbacks: (() => Promise<void>)[] = [];

  /**
   * Sendet eine HTTP-Anfrage an den angegebenen Endpunkt und liefert das Ergebnis zurück.
   * Bei einer Nachricht wird diese angezeigt.
   *
   * @param {string} method Die HTTP-Methode, die verwendet wird (GET, POST, etc.)
   * @param {string} action Die URL des Endpunkts
   * @param {string | FormData | null} data Die zu sendenden Daten
   * @param {Record<string, any> | URLSearchParams | undefined} params Die zu sendenden Parameter
   * @param {boolean} contentType Ob die Anfrage als application/x-www-form-urlencoded gesendet wird
   * @param retry
   * @returns {Promise<Response>} Die HTTP-Antwort des Servers
   */
  request(
    method: string, action: string,
    data: string | FormData | null = null,
    params: Record<string, any> | URLSearchParams | undefined = undefined,
    contentType: boolean = false,
    retry: boolean = false
  ): Promise<Response> {
    return new Promise<Response>(async resolve => {
      if (this.requesting && !retry) {
        this.requesting_callbacks.push(async () => resolve(await this.request(method, action, data, params, contentType, true)));

        return;
      }

      let options = {
        method: method,
        body: data,
        credentials: "include"
      } as RequestInit;

      if (contentType) {
        options.headers = {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        };
      }

      this.requesting = true;

      const responseObject = await fetch(this.API_HOST + action + ((params) ? new URLSearchParamsPlus(params) : ""), options).catch(() => {
        return {
          status: 0,
          headers: new Headers(),
          text: async () => ""
        };
      });

      if (responseObject.headers.has("Refresh")) {
        setTimeout(() => {
          location.reload();
        }, Number(responseObject.headers.get("Refresh")) * 1000);
      }

      let response: Response = {code: responseObject.status} as Response;

      this.requesting = false;

      try {
        response = Object.assign(response, JSON.parse(await responseObject.text()));
      } catch (e) {
        return response;
      }

      if (response.message) {
        Tools.showMessage(response.message, response.status);
      }

      if (response.code === 403) {
        if (!Boolean(localStorage.getItem("logging_out"))) {
          localStorage.setItem("logging_out", "true");

          this.logout.next(true);
        }

        resolve(response);
        return;
      }

      while (this.requesting_callbacks.length) {
        await this.requesting_callbacks.shift()!();
      }

      this.requesting_callbacks = [];

      resolve(response);
      return;
    });
  }
}
