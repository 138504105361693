// status-icon.pipe.ts
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: "getStatus"})
export class StatusIconPipe implements PipeTransform {
  /**
   * Status: 0 - pending, 1 - sent, 2 - received, 3 - failed
   */
  transform(value: number): string {
    switch (value) {
      case 0:
        return '<i class="bi bi-send-fill"></i>';
      case 1:
        return '<i class="bi bi-send-arrow-up-fill"></i>';
      case 2:
        return '<i class="bi bi-send-check-fill"></i>';
      case 3:
      case 4:
        return '<i class="bi bi-send-exclamation-fill"></i>';
      default:
        return '';
    }
  }
}
