import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: "formatTime"})
export class FormatTimePipe implements PipeTransform {

  transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = "";

    if (hours > 0) {
      result += `${hours}h `;
    }

    if (minutes > 0) {
      result += `${minutes}m `;
    }

    if (result === "") {
      result += `${remainingSeconds}s`;
    }

    return result.trim();
  }

}
