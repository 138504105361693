import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class VisibilityChangeService {
  private handlers: any = [];

  constructor() {
    document.addEventListener("visibilitychange", this.visibilityChangeHandler);
    document.addEventListener("pageshow", this.visibilityChangeHandler);
  }

  addHandler(id: any, callable: any, prioritize: boolean = false) {
    if (this.handlers.some((handlerInfo: any) => handlerInfo.id === id)) {
      return;
    }

    this.handlers.push({
      id: id,
      callable: callable,
      prioritize: prioritize
    });
  }

  removeHandler(id: any) {
    this.handlers = this.handlers.filter((handlerInfo: any) => handlerInfo.id !== id);
  }

  private visibilityChangeHandler = () => {
    const prioritize = this.handlers.filter((handlerInfo: any) => handlerInfo.prioritize);
    const normal = this.handlers.filter((handlerInfo: any) => !handlerInfo.prioritize);

    prioritize.forEach((handlerInfo: any) => handlerInfo.callable());
    normal.forEach((handlerInfo: any) => handlerInfo.callable());
  }
}
