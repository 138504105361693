import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {db} from "../db/db";
import {SwUpdate} from "@angular/service-worker";
import {Message} from "../assets/js/model/Message";
import {HomeService} from "../assets/js/service/home";
import {Tools} from "../assets/js/tools";
import {SubscriptionService} from "../assets/js/service/subscription";
import {VisibilityChangeService} from "../assets/js/service/visibility";
import {ChatService} from "../assets/js/service/chat";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private eventHandler = (event: MessageEvent) => this.handleSwMessage(event);

  private deleteInterval: any;

  constructor(private visibilityService: VisibilityChangeService, private homeService: HomeService, private chatService: ChatService, private subscriptionService: SubscriptionService, private swUpdate: SwUpdate, private renderer: Renderer2) {
    db.init().then(() => this.deleteMessages);

    this.checkForUpdates();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", this.eventHandler);

      navigator.serviceWorker.ready.then((registration) => {
        if (!("periodicSync" in registration)) {
          return;
        }

        // @ts-ignore
        registration.periodicSync.getTags().then((tags) => {
          if (!tags.includes("get-messages")) {
            this.loadNextMessages();
          }

          if (!tags.includes("subscribe")) {
            this.subscribeToNotifications();
          }
        });
      });
    }
  }

  private checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(update => {
        if (!update) {
          return;
        }

        if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            command: "clearCache",
          });
        } else this.clearedCache();
      });
    }
  }

  private async clearedCache() {
    await new Promise<void>(async resolve => {
      if (this.swUpdate.isEnabled) {
        const update = await this.swUpdate.checkForUpdate();
        if (!update) {
          resolve();
          return;
        }

        await this.swUpdate.activateUpdate();
      }
    });

    document.location.reload();
  }

  private handleSwMessage(event: MessageEvent) {
    if (event.data && event.data.command) {
      switch (event.data.command) {
        case "reloadMessages":
          this.homeService.updateEvent.next(true);
          this.chatService.updateEvent.next(true);

          break;
        case "clearCache":
          this.clearedCache();

          break;
        case "shareTarget":
          console.log("Setting share data: " + event.data.share_data);

          this.chatService.share_data = event.data.share_data;

          break;
      }
    }
  }

  private async loadNextMessages() {
    const registration = await navigator.serviceWorker.ready;
    try {
      if ("periodicSync" in registration) {
        // @ts-ignore
        await registration.periodicSync.register("get-messages", {
          minInterval: 30 * 60 * 1000, // 30 minutes
        });
      }
    } catch (e) {
    }
  }

  private async subscribeToNotifications() {
    const registration = await navigator.serviceWorker.ready;
    try {
      if ("periodicSync" in registration) {
        // @ts-ignore
        await registration.periodicSync.register("subscribe", {
          minInterval: 2 * 60 * 60 * 1000, // 2 hours
        });
      }
    } catch (e) {
    }
  }

  private deleteMessages() {
    db.connection.select({
      from: "messages",
      where: {
        timer: {
          "!=": 0
        }
      }
    }).then((messages: Message[]) => {
      const currentTimestamp = Math.round(Date.now() / 1000);
      const messageIds = messages.filter(message =>
        (message.open_timestamp) ? message.open_timestamp + message.timer < currentTimestamp : false).map((message: Message) => message.message_id);

      if (messageIds.length > 0) {
        db.connection.remove({
          from: "messages",
          where: {
            message_id: {in: messageIds}
          }
        }).catch(e => {
        });
      }
    }).catch(e => {
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const styleTag = document.querySelector("[href*='styles.css']");
      this.renderer.insertBefore(document.head, styleTag, document.head.lastElementChild);
    });
  }

  ngOnInit() {
    this.visibilityService.addHandler("app", () => {
      if (Tools.isVisible()) {
        this.deleteMessages();
        this.checkForUpdates();

        if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({command: "resetBadge"});
        }

        this.subscriptionService.subscribe();
      }
    });

    this.deleteInterval = setInterval(() => {
      this.deleteMessages();
    }, 15000);

    this.homeService.updateTheme();
  }

  ngOnDestroy() {
    if (this.deleteInterval) {
      clearInterval(this.deleteInterval);
    }

    this.visibilityService.removeHandler("app");
  }
}
