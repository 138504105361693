<h2>Willkommen zurück!</h2>
<form (ngSubmit)="decrypt()" [formGroup]="formGroup">
  <div class="mb-3 d-none">
    <label class="form-label" for="username">Benutzername</label>
    <input tabindex="1" autocomplete="username" class="form-control" formControlName="username" id="username" placeholder="Kevin" type="text">
  </div>
  <div class="mb-3">
    <label class="form-label" for="password">Passwort</label>
    <div class="d-flex">
      <input tabindex="1" autocomplete="password" class="form-control d-inline" formControlName="password" id="password" placeholder="***********" type="password" autofocus>
      <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
    </div>
  </div>
  <button [disabled]="!this.updated || !formGroup.valid" class="btn btn-primary" type="submit">Entsperren</button>
</form>
