import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {AuthService} from "../../assets/js/service/auth";
import {ChatService} from "../../assets/js/service/chat";
import {ScreenSizeService} from "../../assets/js/service/screen";
import {CryptUtils} from "../../assets/js/crypt_utils";

import {User} from "../../assets/js/model/User";
import {Member} from "../../assets/js/model/Member";

import {db} from "../../db/db";
import {MenuItem} from "../../assets/js/interface/menu_item";
import {MenuAction} from "../../assets/js/interface/menu_action";
import {ChatComponent} from "../chat/chat.component";
import {Subscription} from 'rxjs';
import {Message, MessageStatus} from "../../assets/js/model/Message";
import {HomeService} from "../../assets/js/service/home";
import {ContextmenuService} from "../../assets/js/service/contextmenu";
import {NetworkService} from "../../assets/js/service/network";
import {Response, Tools} from "../../assets/js/tools";
import {NgxScannerQrcodeComponent, ScannerQRCodeConfig} from "ngx-scanner-qrcode";
import {ScannerQRCodeResult} from "ngx-scanner-qrcode/lib/ngx-scanner-qrcode.options";
import {SecretKey} from "../../assets/js/model/SecretKey";
import {FileInfo} from "../../assets/js/model/FileInfo";
import {ChatSettings} from "../../assets/js/model/ChatSettings";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {VisibilityChangeService} from "../../assets/js/service/visibility";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private eventHandler = (event: MessageEvent) => this.handleSwMessage(event);

  private secretInterval: any;
  protected base64JSON: string = "";
  protected mainCamera: string = "";

  private subscriptions: Subscription[] = [];

  protected scannerConfig: ScannerQRCodeConfig = {
    vibrate: 0,
    isBeep: false,
    constraints: {
      audio: false
    }
  };

  @ViewChild(ChatComponent)
  chatComponent!: ChatComponent;

  @ViewChild(NgxScannerQrcodeComponent)
  private scanner!: NgxScannerQrcodeComponent;

  @ViewChild("chatList")
  private chatList!: ElementRef;

  contextMenuItems: MenuItem[] = [
    {
      label: "Vertrauen", action: "trustUser", condition: (user: User) => {
        return !user.trusted
      }
    },
    {
      label: "Nicht vertrauen", action: "untrustUser", condition: (user: User) => {
        return typeof user.trusted !== "undefined" && user.trusted
      }
    },
    {label: "Umbenennen", action: "renameChat"},
    {label: "Archivieren", action: ""},
    {label: "Löschen", action: "deleteChat"},
  ];

  protected loading: boolean = false;

  constructor(private visibilityService: VisibilityChangeService, private cdr: ChangeDetectorRef, private dbHandlerService: DBHandlerService, private networkService: NetworkService, protected authService: AuthService, protected homeService: HomeService, protected chatService: ChatService, protected screenService: ScreenSizeService, private contextmenuService: ContextmenuService) {
  }

  onContextMenuAction(actionEvent: MenuAction) {
    // @ts-ignore
    this[actionEvent.action](...Object.values(actionEvent.data));
  }

  async untrustUser(chat_id: string) {
    const member = await this.homeService.getMember(chat_id);
    const members = await this.homeService.getMembers(member.user_id!);

    for (const member of members) {
      member.user!.trusted = false;
    }

    await db.connection.update({
      in: "users",
      set: {
        trusted: false
      },
      where: {
        user_id: member.user_id!
      }
    }).catch(e => {
    });
  }

  async trustUser(chat_id: string) {
    const member = await this.homeService.getMember(chat_id);
    const members = await this.homeService.getMembers(member.user_id!);

    for (const member of members) {
      member.user!.trusted = true;
    }

    await db.connection.update({
      in: "users",
      set: {
        trusted: true
      },
      where: {
        user_id: member.user_id!
      }
    }).catch(e => {
    });

    const image = this.authService.getImage();

    if (!image.size) {
      return;
    }

    const picture = await this.homeService.updatePicture(image, chat_id);
    await this.networkService.request("PUT", "/pictures", JSON.stringify({images: [picture]}));
  }

  renameChat(chat_id: string) {
    Swal.fire({
      title: "Geben Sie den neuen Namen ein",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Ändern",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async (name) => {
        db.connection.update({
          in: "chats",
          set: {
            name: name,
          },
          where: {
            chat_id: chat_id!,
          }
        }).then(() => {
          const index = this.homeService.members.findIndex(member => member.chat?.chat_id === chat_id);
          this.homeService.members[index].chat!.name = name;
        }).catch(() => {
          Swal.fire({
            title: "Fehler",
            text: "Beim Ändern des Namens ist ein Fehler aufgetreten",
            icon: "error"
          });
        })
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  deleteChat(chat_id: string) {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Der Chat wird unwiderruflich gelöscht",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Löschen",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        this.networkService.request("DELETE", "/chat/" + chat_id);

        const showError = () => {
          Swal.fire({
            title: "Fehler",
            text: "Beim Löschen des Chats ist ein Fehler aufgetreten",
            icon: "error"
          });
        }

        await db.connection.remove({
          from: "members",
          where: {
            chat_id: chat_id!,
          }
        }).catch(() => showError());

        await db.connection.remove({
          from: "messages",
          where: {
            chat_id: chat_id!,
          }
        }).catch(() => showError());

        await db.connection.remove({
          from: "secret_keys",
          where: {
            chat_id: chat_id!,
          }
        }).catch(() => showError());

        await db.connection.remove({
          from: "chats",
          where: {
            chat_id: chat_id!,
          }
        }).then(() => {
          const index = this.homeService.members.findIndex(member => member.chat?.chat_id === chat_id);
          this.homeService.members.splice(index, 1);

          if (this.chatService.chatId === chat_id) {
            this.chatComponent.unloadChat();
          }
        }).catch(() => showError());
      }
    });
  }

  search() {
    Swal.fire({
      title: "Neuer Chat",
      text: "Benutzer suchen und Chat erstellen",
      footer: "Sie können mehrere Chats mit dem gleichen Benutzer erstellen und diese für verschiedene Themen, Zwecke etc. verwenden",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Suchen",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async (username) => {
        const response = await this.networkService.request("GET", "/user/" + username);
        if (response.data && response.data.length === 1) {
          const user = response.data[0] as User;

          this.createChat(user);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  private async generateSecretKey() {
    const secret_key = await CryptUtils.generateSecretKey();

    const data = {
      secret_key: await CryptUtils.exportSecretKey(secret_key),
      user_secret_key: {
        key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
        type: "aes"
      },
      user_id: this.authService.getUserId()
    };

    this.base64JSON = JSON.stringify(data);
  }

  secretKeyGenerator() {
    this.generateSecretKey();

    this.secretInterval = setInterval(() => {
      this.generateSecretKey();
    }, 15000);
  }

  protected openScanner(): void {
    try {
      this.scanner.start(() => {
        this.scanner.playDevice(this.mainCamera);
      });
    } catch (e) {
      Tools.showMessage("Kein Zugriff auf die Kamera möglich", "error");
    }
  }

  protected closeQR(): void {
    clearInterval(this.secretInterval);

    this.scanner.stop();
    this.base64JSON = "";
  }

  protected async handleClick(chatId: string, index: number) {
    if (this.contextmenuService.element?.visible) {
      return;
    }

    const member = this.homeService.sorted_members[index];
    member.unseen_messages = 0;

    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({command: "resetBadge"});
    }

    this.chatComponent.loadChat(chatId);
  }

  async loadPictures(pictures: any[] = []): Promise<void> {
    if (!pictures.length) {
      return;
    }

    const encryptedPictures = [];
    for (const pictureInfo of pictures) {
      let encrypted = pictureInfo.picture;
      let decrypted = null;

      const member = await this.homeService.getMember(pictureInfo.chat_id);
      if (pictureInfo.type === "RSA") {
        const secret_keys = (await db.connection.select({
          from: "secret_keys",
          where: {
            "chat_id": pictureInfo.chat_id
          }
        }).catch(e => {
        })) as SecretKey[];

        if (!secret_keys.length) {
          return;
        }

        const secret_key = await CryptUtils.decryptSecretKeySymmetric(secret_keys[0].secret_key, this.authService.getSecretKey()!) as CryptoKey;
        decrypted = await CryptUtils.decryptData(encrypted, secret_key);
        encrypted = await CryptUtils.encryptData(decrypted, this.authService.getSecretKey()!);

        encryptedPictures.push({
          chat_id: pictureInfo.chat_id,
          user_id: member.user_id,
          for_id: this.authService.getUserId(),
          type: "AES",
          picture: encrypted
        });
      } else {
        decrypted = await CryptUtils.decryptData(encrypted, this.authService.getSecretKey()!);
      }

      const members = await this.homeService.getMembers(member.user_id!);
      for (const member of members) {
        member.user!.picture = JSON.parse(decrypted) as FileInfo;
      }

      this.dbHandlerService.addAction(async () => {
        await db.connection.update({
          in: "users",
          set: {
            picture: encrypted
          },
          where: {
            user_id: member.user_id!
          }
        }).catch(e => {
        });
      });
    }

    await this.networkService.request("PUT", "/pictures", JSON.stringify({images: encryptedPictures}));

    localStorage.setItem("pictureTimestamp", Tools.current_time.toString());
  }

  async updateData() {
    if (Tools.updating) {
      return;
    }

    Tools.updating = true

    await this.loadChats();
    await this.loadMessageBadges();

    try {
      const unsent_messages = await db.connection.selectTop({
        from: "messages",
        where: {
          sender_id: this.authService.getUserId(),
          status: MessageStatus.ERROR
        },
        top: 30
      }) as Message[];

      const messages = await db.connection.select({
        from: "messages",
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: 1
      });

      const message_timestamp = (messages.length) ? messages[0].timestamp : 0;
      const picture_timestamp = Number(localStorage.getItem("pictureTimestamp")) ?? 0;

      const response = await this.networkService.request("POST", "/batch/chats", JSON.stringify({
        messages: unsent_messages,
        message_timestamp: message_timestamp,
        picture_timestamp: picture_timestamp
      }));

      if (response.data) {
        for (const member of response.data.members as Member[]) {
          await this.homeService.processMember(member);
        }

        const messages = response.data.messages as Message[];

        await this.chatService.handleMessages(messages);
        this.chatService.addMessages(messages);

        this.cdr.detectChanges();

        await this.chatService.sentMessages(unsent_messages);

        await this.loadPictures(response.data.pictures);
      }
    } catch (e) {
    }

    Tools.updating = false;
  }

  ngOnInit() {
    if (!this.authService.isDecrypted) {
      return;
    }

    this.updateData();

    this.subscriptions.push(
      this.dbHandlerService.finished.subscribe(async finished => {
        if (finished) {
          await this.loadMessageBadges();
          this.dbHandlerService.finished.next(false);
        }
      }),
      this.homeService.badges.subscribe(async reload => {
        if (reload) {
          await this.loadMessageBadges();
          this.homeService.badges.next(false);
        }
      }),
      this.homeService.updateEvent.subscribe(async update => {
        if (update) {
          await this.updateData();
          this.chatList.nativeElement.scrollTop = 0;
          this.homeService.updateEvent.next(false);

          this.cdr.detectChanges();
        }
      })
    );

    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener("message", this.eventHandler);

      navigator.serviceWorker.controller.postMessage({command: "resetBadge"});
    }

    this.visibilityService.addHandler("home", () => {
      if (Tools.isVisible()) {
        this.updateData();
      }
    }, true);
  }

  ngAfterViewInit() {
    this.chatList.nativeElement.addEventListener("scroll", this.scrollChangeHandler);

    this.subscriptions.push(
      this.scanner.devices.subscribe(devices => {
        if (!devices.length) {
          return;
        }

        const device = devices.find(f => (/back|trás|rear|traseira|environment|ambiente/gi.test(f.label))) ?? devices.pop()!;
        this.mainCamera = device.deviceId;
      }),
      this.scanner.data.subscribe(async (result: ScannerQRCodeResult[]) => {
          if (!result.length) {
            return;
          }

          this.scanner.stop();

          const data = JSON.parse(result[0].value);
          const secret_key = await CryptUtils.importSecretKey(data.secret_key);

          const creator_secret_key = {
            key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
            type: "aes"
          };

          const response = await this.chatService.createChat(data.user_id, data.user_secret_key, creator_secret_key);
          this.loadChat(response);
        }
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.removeEventListener("message", this.eventHandler);
    }

    this.chatList.nativeElement.removeEventListener("scroll", this.scrollChangeHandler);
  }

  private handleSwMessage(event: MessageEvent) {
    if (event.data && event.data.command) {
      switch (event.data.command) {
        case "openChat":
          this.chatComponent.loadChat(event.data.chat_id);
          break;
      }
    }
  }

  private scrollChangeHandler = () => {
    if (!this.loading) {
      this.homeService.lastScrollPosition = this.chatList.nativeElement.scrollTop;
    }
  }

  private async loadMessageBadges() {
    const messages = await db.connection.selectTop({
      from: "messages",
      where: {
        seen: "false"
      },
      order: {
        by: "timestamp",
        type: "desc"
      },
      top: 100
    }).catch(() => []) as Message[];

    for (const member of this.homeService.members) {
      member.unseen_messages = messages.filter(message => message.chat_id === member.chat_id).length;
    }

    this.chatList.nativeElement.scrollTop = this.homeService.lastScrollPosition;
  }

  private loadChat(response: Response): void {
    if (response.data) {
      this.chatList.nativeElement.scrollTop = 0;
      this.homeService.lastScrollPosition = 0;

      this.chatComponent.loadChat(response.data.chat!.chat_id);
    }
  }

  private async loadChats() {
    if (this.homeService.members.length) {
      return;
    }

    this.homeService.members = await db.connection.select({
      from: "members",
      where: {
        owner_id: this.authService.getUserId(),
      },
      join: [{
        with: "chats",
        on: "members.chat_id = chats.chat_id",
        joinUnder: "chat",
      },
        {
          with: "users",
          on: "members.user_id = users.user_id",
          joinUnder: "user",
        }]
    }).then(async members => {
      for (const member of members) {
        member.chat!.settings = JSON.parse(member.chat!.settings as string) as ChatSettings;

        if (member.chat!.is_group || !member.user!.picture || typeof member.user!.picture !== "string") {
          continue;
        }

        const image_data = await CryptUtils.decryptData(member.user!.picture, this.authService.getSecretKey()!);
        member.user!.picture = JSON.parse(image_data) as FileInfo;
      }

      return members;
    }).catch(() => []);

    this.chatList.nativeElement.scrollTop = this.homeService.lastScrollPosition;
  }

  private async createChat(user: User) {
    const secret_key = await CryptUtils.generateSecretKey();
    const user_public_key = await CryptUtils.getPublicKey(user.public_key as string);
    const user_secret_key = {
      key: await CryptUtils.encryptSecretKey(secret_key, user_public_key),
      type: "rsa"
    };
    const creator_secret_key = {
      key: await CryptUtils.encryptSecretKeySymmetric(secret_key, this.authService.getSecretKey() as CryptoKey),
      type: "aes"
    };

    const response = await this.chatService.createChat(user.user_id, user_secret_key, creator_secret_key);
    this.loadChat(response);
  }

  protected readonly Tools = Tools;
}
