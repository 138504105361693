import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  bigScreen = new BehaviorSubject<boolean>(false);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      this.bigScreen.next(!result.matches);
    });
  }

  isBigScreen() {
    return this.bigScreen.value;
  }
}
