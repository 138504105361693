import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Member} from "../model/Member";
import {db} from "../../../db/db";
import {AuthService} from "./auth";
import {Tools} from "../tools";
import {FormatTimePipe} from "../pipe/format_time";
import {Message, MessageStatus} from "../model/Message";
import {NetworkService} from "./network";
import {CryptUtils} from "../crypt_utils";
import {FileInfo} from "../model/FileInfo";
import {SecretKey} from "../model/SecretKey";
import {User} from "../model/User";
import {DBHandlerService} from "./db_handler";

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  members: Member[] = [];
  lastScrollPosition: number = 0;

  isTouching: boolean = false;

  badges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private insertedUsers: string[] = [];

  constructor(private dbHandlerService: DBHandlerService, private authService: AuthService, private networkService: NetworkService) {
  }

  get sorted_members() {
    return [...this.members].sort((a, b) => {
      const timestampA = a.timestamp ?? 0, timestampB = b.timestamp ?? 0;

      return timestampA - timestampB;
    }).reverse();
  }

  async getMember(chatId: string): Promise<Member> {
    let member: Member = {} as Member;

    const index = this.members.findIndex(m => m.chat!.chat_id === chatId);
    if (index !== -1) {
      member = this.members[index];
    } else {
      const response = await this.networkService.request("GET", "/chat/" + chatId + "/info");
      if (response.data) {
        member = response.data as Member;

        await this.newMember(member);
      }
    }

    return member;
  }

  async getMembers(userId: string): Promise<Member[]> {
    let members: Member[] = [];

    for (const member of this.members) {
      if (member.user!.user_id === userId) {
        members.push(member);
      }
    }

    return members;
  }

  async processMember(member: Member): Promise<void> {
    const index = this.members.findIndex(m => m.chat?.chat_id === member.chat?.chat_id);
    if (index === -1) {
      await this.newMember(member);
    } else {
      let changed = false;

      const current_member = this.members[index];
      if (Number(current_member.chat!.settings.timer) !== Number(member.chat!.settings.timer)) {
        const message = {
          message_id: Tools.generateUUID(),
          chat_id: member.chat!.chat_id,
          sender_id: "system",
          data: member.user!.username + " hat den Timer auf " + new FormatTimePipe().transform(member.chat!.settings.timer) + " geändert",
          files: "",
          timestamp: Tools.current_time,
          timer: Number(member.chat!.settings.timer),
          status: MessageStatus.RECEIVED,
          seen: false,
        } as Message;

        this.dbHandlerService.addAction(async () => {
          await db.connection.insert({
            into: "messages",
            values: [message],
          }).catch(e => {
          });
        });

        changed = true;
      }

      current_member.chat!.settings = member.chat!.settings;

      if (current_member.user!.sign_public_key !== member.user!.sign_public_key) {
        const message = {
          message_id: Tools.generateUUID(),
          chat_id: member.chat!.chat_id,
          sender_id: "system",
          data: "Der Sicherheitsschlüssel von " + member.user!.username + " hat sich geändert",
          files: "",
          timestamp: Tools.current_time,
          timer: Number(member.chat!.settings.timer),
          status: MessageStatus.RECEIVED,
          seen: false,
        } as Message;

        this.dbHandlerService.addAction(async () => {
          await db.connection.insert({
            into: "messages",
            values: [message],
          }).catch(e => {
          });
        });

        changed = true;
      }

      if (changed) {
        this.updateTimestamp(current_member, Tools.current_time);
      }

      current_member.user!.sign_public_key = member.user!.sign_public_key;
    }
  }

  async processUser(user: User): Promise<void> {
    if (this.insertedUsers.includes(user!.user_id)) {
      return;
    }

    const count = await db.connection.count({
      from: "users",
      where: {
        user_id: user!.user_id
      }
    });
    if (count === 0) {
      this.insertedUsers.push(user!.user_id);
      this.dbHandlerService.addAction(async () => {
        await db.connection.insert({
          into: "users",
          values: [user]
        }).catch(e => {
        });
      });
    } else {
      this.dbHandlerService.addAction(async () => {
        await db.connection.update({
          in: "users",
          set: {
            sign_public_key: user!.sign_public_key,
            username: user!.username
          },
          where: {
            user_id: user!.user_id,
          }
        }).catch(e => {
        });
      });

      const users = await db.connection.select({
        from: "users",
        where: {
          user_id: user!.user_id
        }
      }).catch(e => {
      }) as User[];

      if (users.length) {
        user = users[0] as User;

        if (user!.picture && typeof user!.picture === "string") {
          const image_data = await CryptUtils.decryptData(user.picture as string, this.authService.getSecretKey()!);
          user.picture = JSON.parse(image_data) as FileInfo;
        }
      }
    }
  }

  async newMember(member: Member): Promise<void> {
    let count;

    member.owner_id = this.authService.getUserId();

    await this.processUser(member.user!);

    count = await db.connection.count({
      from: "chats",
      where: {
        chat_id: member.chat!.chat_id
      }
    });
    if (count === 0) {
      this.dbHandlerService.addAction(async () => {
        await db.connection.insert({
          into: "chats",
          values: [member.chat]
        }).catch(e => {
        });
      });
    } else {
      this.dbHandlerService.addAction(async () => {
        await db.connection.update({
          in: "chats",
          set: {
            settings: JSON.stringify(member.chat!.settings),
          },
          where: {
            chat_id: member.chat!.chat_id
          }
        }).catch(e => {
        });
      });
    }

    count = await db.connection.count({
      from: "members",
      where: {
        entry_id: member.entry_id!
      }
    });
    if (count === 0) {
      this.dbHandlerService.addAction(async () => {
        await db.connection.insert({
          into: "members",
          values: [member]
        }).catch(e => {
        });
      });
    } else {
      this.dbHandlerService.addAction(async () => {
        await db.connection.update({
          in: "members",
          set: {
            timestamp: member.timestamp,
          },
          where: {
            entry_id: member.entry_id!
          }
        }).catch(e => {
        });
      });
    }

    this.members.push(Tools.convertStringsToBooleans(member));
  }

  updateTheme() {
    const html = document.querySelector("html")!;
    const meta = document.querySelector("meta[name='theme-color']")!;
    html.dataset["bsTheme"] = this.authService.hasDarkMode() ? "dark" : "light";
    meta.setAttribute("content", this.authService.hasDarkMode() ? "#212529" : "#ffffff");


    localStorage.setItem("dark_mode", this.authService.hasDarkMode() ? "true" : "");
  }

  updateTimestamp(member: Member, timestamp: number): void {
    member.timestamp = timestamp;

    this.dbHandlerService.addAction(async () => {
      await db.connection.update({
        in: "members",
        set: {
          timestamp: timestamp
        },
        where: {
          entry_id: member.entry_id!
        }
      }).catch(e => {
      });
    });
  }

  async updatePictures(image: FileInfo): Promise<void> {
    const users = await db.connection.select({
      from: "users",
      where: {
        trusted: true
      }
    }).catch(e => {
    }) as Member[];

    if (!users.length) {
      return;
    }

    const encryptedPictures = [];
    for (const user of users) {
      const members = await db.connection.select({
        from: "members",
        where: {
          user_id: user.user_id!
        },
        limit: 1
      }).catch(e => {
      }) as Member[];

      if (!members.length) {
        continue;
      }

      encryptedPictures.push(await this.updatePicture(image, members[0].chat_id!));
    }

    await this.networkService.request("PUT", "/pictures", JSON.stringify({images: encryptedPictures}))
  }

  async updatePicture(image: FileInfo, chat_id: string): Promise<{}> {
    const secretKeys = await db.connection.select({
      from: "secret_keys",
      where: {
        chat_id: chat_id,
        user_id: this.authService.getUserId()
      }
    }).catch(e => {
    }) as SecretKey[];

    if (!secretKeys.length) {
      return {};
    }

    const member = await this.getMember(chat_id);
    const secretKey = await CryptUtils.decryptSecretKeySymmetric(secretKeys[0].secret_key as string, this.authService.getSecretKey()!);
    const encrypted = await CryptUtils.encryptData(JSON.stringify(image), secretKey!);

    return {
      chat_id: chat_id,
      user_id: this.authService.getUserId(),
      for_id: member.user_id,
      type: "RSA",
      picture: encrypted,
    }
  }
}
