import {Component, Input} from '@angular/core';
import {Member} from "../../../assets/js/model/Member";
import {Tools} from "../../../assets/js/tools";

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss'
})
export class ProfilePictureComponent {
  @Input("member")
  member!: Member;

  protected readonly Tools = Tools;
}
