import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DBHandlerService {
  private callableQueue: any[] = [];
  private isRunning: boolean = false;

  private processTimeout: any;

  finished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  addAction(callable: any): void {
    this.callableQueue.push(callable);

    if (!this.isRunning) {
      clearTimeout(this.processTimeout);
      this.processTimeout = setTimeout(() => {
        this.processActions();
      }, 50);
    }
  }

  private async processActions(): Promise<void> {
    this.isRunning = true;

    while (this.callableQueue.length) {
      await this.callableQueue.shift()!();
    }

    this.finished.next(true);
    this.isRunning = false;
  }
}
