import {AfterContentChecked, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Message, MessageStatus} from "../../../assets/js/model/Message";
import {AuthService} from "../../../assets/js/service/auth";
import {MenuItem} from "../../../assets/js/interface/menu_item";
import {MenuAction} from "../../../assets/js/interface/menu_action";
import {FileInfo} from "../../../assets/js/model/FileInfo";
import {db} from "../../../db/db";
import {CryptUtils} from "../../../assets/js/crypt_utils";

import {ChatService} from "../../../assets/js/service/chat";
import {Tools} from "../../../assets/js/tools";
import {ContextmenuService} from "../../../assets/js/service/contextmenu";
import {NetworkService} from "../../../assets/js/service/network";
import {FileViewerService} from "../../../assets/js/service/fileviewer";
import {DBHandlerService} from "../../../assets/js/service/db_handler";
import {FileUtils} from "../../../assets/js/file_utils";
import {ContextmenuComponent} from "../contextmenu/contextmenu.component";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit, AfterContentChecked {
  @Input()
  message!: Message;
  processed: boolean = false;
  checked: boolean = false;

  private intervals: any = [];
  private timeouts: any = [];

  protected references: any = [];
  protected referencesIndex: number = 0;

  @ViewChild("referencesContainer")
  private referencesContainer!: ElementRef;

  @ViewChild("decryptedMessage")
  private decryptedMessage!: ElementRef;

  protected isArray = Array.isArray;
  protected MessageStatus = MessageStatus;

  contextMenuItems: MenuItem[] = [
    {label: "Antworten", action: "referenceMessage"},
    {label: "Kopieren", action: "copyMessage"},
    {
      label: "Bearbeiten", action: "editMessage", condition: () => {
        return this.message.sender_id === this.authService.getUserId()
      }
    },
    {label: "Löschen", action: "deleteMessage"},
  ];

  constructor(private renderer: Renderer2, private dbHandlerService: DBHandlerService, private fileViewerService: FileViewerService, protected authService: AuthService, protected chatService: ChatService, private contextmenuService: ContextmenuService, private networkService: NetworkService) {
  }

  onContextMenuAction(actionEvent: MenuAction | Event) {
    // @ts-ignore
    this[actionEvent.action](...Object.values(actionEvent.data));
  }

  private deleteMessage() {
    this.chatService.removeMessage(this.message, false);

    this.dbHandlerService.addAction(async () => {
      await db.connection.update({
        in: "messages",
        set: {
          sender_id: "",
          data: "",
          files: "",
          signature: "",
          status: 5
        },
        where: {
          message_id: this.message.message_id!
        }
      }).catch(e => {
      });
    });

    this.networkService.request("DELETE", "/chat/" + this.message.chat_id + "/message/" + this.message.message_id);
  }

  protected showItem(index: number) {
    const items = this.referencesContainer.nativeElement.querySelectorAll(".reference");

    if (items.length <= 1 || index === this.referencesIndex) {
      return;
    }

    if (index >= items.length) {
      index = 0;
    } else if (index < 0) {
      index = items.length - 1;
    }

    const currentItem = items[this.referencesIndex];
    const nextItem = items[index];

    const direction =
      (this.referencesIndex === (items.length - 1) && index === 0) ? "left"
      : (index === (items.length - 1) && this.referencesIndex === 0) ? "right"
        : (this.referencesIndex < index) ? "left" : "right";

    const exitingClass = direction === "left" ? "exiting-to-left" : "exiting-to-right";

    currentItem.classList.add(exitingClass);
    currentItem.classList.remove("active");

    nextItem.classList.add("active");

    setTimeout(() => {
      currentItem.classList.remove(exitingClass);
    }, 500);

    this.referencesIndex = index;
  }

  private async editMessage() {

  }

  private async referenceMessage() {
    this.chatService.referenceEvent.next(this.message.message_id!);
  }

  private async copyMessage() {
    if (!this.decryptedMessage || !this.decryptedMessage.nativeElement) {
      return;
    }

    const blob = new Blob([
      (this.decryptedMessage.nativeElement.innerText || this.decryptedMessage.nativeElement.textContent!).trim()
    ], {type: "text/plain"});
    const clipboardItem = new ClipboardItem({"text/plain": blob});

    navigator.clipboard.write([clipboardItem]).then(() => Tools.showMessage("Nachricht kopiert", "success"));
  }

  async processMessage(message: Message, decrypt: boolean = false): Promise<Message> {
    if (message.processed || (message.status === MessageStatus.ENCRYPTED && !decrypt)) {
      this.processed = true;

      return message;
    }

    if (message.sender_id !== "system" && message.status !== MessageStatus.DELETED) {
      if (message.sender_id !== this.authService.getUserId()) {
        if (!await CryptUtils.verifySign(message.data, message.signature as string, this.chatService.sign_public_key!)) {
          message.status = MessageStatus.INVALID_SIGNATURE;
        } else {
          message.status = MessageStatus.RECEIVED;
        }
      }

      message.data = await CryptUtils.decryptData(message.data, this.chatService.secret_key!);
      message.data = message.data.replaceAll(/<(?!\/?(span|p|sup|br|div)\b)[^>]+>/gi, "");
      message.data = message.data.replace(/<(span|p|sup|br|div)\b([^>]*)>/gi, function (match, tagName, attributes) {
        // Match and keep 'data-message_id' or 'class' attributes
        let allowedAttributes = attributes.match(/\b(data-message_id|class)="[^"]*"/gi);
        // Rebuild the tag with only the allowed attributes
        return `<${tagName}${allowedAttributes ? ' ' + allowedAttributes.join(' ') : ''}>`;
      });

      message.data = message.data.replaceAll("\n", "<br/>");
      message.data = message.data.replaceAll(/(<br\s*\/?>)+$/gi, "<br/>");
      message.data = message.data.replace(/<br\s*\/?>$/, "");
      message.data = this.replaceURLsWithLinks(message.data);

      if (message.files.length) {
        try {
          message.files = JSON.parse(await CryptUtils.decryptData(message.files, this.chatService.secret_key!)) as FileInfo[];
        } catch (e) {
        }
      }
    }

    if (!message.open_timestamp) {
      message.open_timestamp = Tools.current_time;
      message.seen = true;
    }

    if (message.timer > 0) {
      const timeout = Math.round(message.timer - (Tools.current_time - message.open_timestamp));
      if (timeout <= 0) {
        message.status = MessageStatus.DELETED;
      }

      message.countdown = timeout;

      this.intervals[message.message_id!] = setInterval(() => {
        message.countdown!--;
      }, 1000);

      this.timeouts[message.message_id!] = setTimeout(() => {
        clearInterval(this.intervals[message.message_id!]);

        this.chatService.removeMessage(message);
      }, timeout * 1000);
    }

    this.dbHandlerService.addAction(async () => {
      await db.connection.update({
        in: "messages",
        set: {
          status: message.status,
          open_timestamp: message.open_timestamp,
          seen: message.seen
        },
        where: {
          message_id: message.message_id!
        }
      }).catch(e => {
      });
    });

    message.processed = true;

    this.processed = true;

    return message;
  }

  private replaceURLsWithLinks(text: string) {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function (url) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  protected openFile(index: number): void {
    if (this.contextmenuService.element?.visible) {
      return;
    }

    this.fileViewerService.setFile(this.message.files[index]);
    this.chatService.open_message = this.message;
  }

  protected downloadFile(index: number): void {
    const anchorElement = document.createElement("a");
    const file: FileInfo = this.message.files[index];

    anchorElement.download = file.name;
    anchorElement.href = file.data!;

    anchorElement.click();
  }

  ngOnInit() {
    this.processMessage(this.message);
  }

  ngAfterContentChecked() {
    if (this.checked || !this.decryptedMessage || !this.decryptedMessage.nativeElement) {
      return;
    }

    const elements = this.decryptedMessage.nativeElement.querySelectorAll(".text [data-message_id]");

    if (!elements.length) {
      return;
    }

    const current_scroll = this.chatService.current_scroll;

    elements.forEach(async (element: HTMLElement) => {
      const message_id = element.getAttribute("data-message_id")!;

      const messages = await db.connection.select({
        from: "messages",
        where: {
          message_id: message_id
        },
        join: [{
          with: "users",
          on: "messages.sender_id = users.user_id",
          joinUnder: "sender",
        }]
      }) as Message[];

      if (messages.length) {
        const message = messages[0];
        await this.processMessage(message);

        this.references.push({
          message_id: message_id,
          username: message.sender!.username,
          data: Tools.stripHtml(message.data).slice(0, 50)
        });
      }

      this.renderer.listen(element, "click", this.showMessage.bind(this));

      current_scroll();
    });

    this.checked = true;
  }

  protected showMessage(event: Event) {
    const element = event.target as HTMLElement;
    let message_id = element.getAttribute("data-message_id");

    if (!message_id) {
      message_id = element.closest("[data-message_id]")!.getAttribute("data-message_id")!;
    }

    this.chatService.referenceClickEvent.next(message_id);
  }

  protected readonly FileUtils = FileUtils;
}
