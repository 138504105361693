import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import WaveSurfer from "wavesurfer.js";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subscription} from "rxjs";
import Swal, {SweetAlertOptions} from "sweetalert2";

import {db} from "../../db/db";
import {Response, Tools} from "../../assets/js/tools";

import {ChatService} from "../../assets/js/service/chat";
import {AuthService} from "../../assets/js/service/auth";
import {CryptUtils} from "../../assets/js/crypt_utils";
import {HomeService} from "../../assets/js/service/home";
import {FormatTimePipe} from "../../assets/js/pipe/format_time";
import {NetworkService} from "../../assets/js/service/network";
import {FileViewerService} from "../../assets/js/service/fileviewer";
import {DBHandlerService} from "../../assets/js/service/db_handler";
import {VisibilityChangeService} from "../../assets/js/service/visibility";
import {FileUtils} from "../../assets/js/file_utils";

import {Member} from "../../assets/js/model/Member";
import {Message, MessageStatus} from "../../assets/js/model/Message";
import {SecretKey} from "../../assets/js/model/SecretKey";
import {BlockedUser} from "../../assets/js/model/BlockedUser";
import {FileInfo} from "../../assets/js/model/FileInfo";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];

  private intervals: any = [];
  private timeouts: any = [];

  private max_previous: boolean = false;

  private readonly message_count: number = 20;

  protected selected_files: FileInfo[] = [];
  private file_size: number = 0;

  private processing: boolean = false;
  protected unloading: boolean = false;
  protected loading: boolean = false;
  protected member?: Member;

  @ViewChild("messagesContainer")
  private messagesContainer!: ElementRef;

  @ViewChild("messageInput")
  private messageInput!: ElementRef;

  @ViewChild("scrollToBottom")
  private scrollToBottom!: ElementRef;

  @ViewChild("waveform")
  waveform!: ElementRef;

  protected recording: boolean = false;

  private mediaRecorder: MediaRecorder | null = null;
  private chunks: BlobPart[] = [];
  private waveSurfer: WaveSurfer | null = null;
  private audioType: string | null = null;

  protected blocked: boolean = false;

  constructor(private visibilityService: VisibilityChangeService, private dbHandlerService: DBHandlerService, private route: ActivatedRoute, private fileViewerService: FileViewerService, private networkService: NetworkService, protected authService: AuthService, private homeService: HomeService, protected chatService: ChatService, private deviceService: DeviceDetectorService) {
  }

  isSameDay(currentIndex: number): boolean {
    if (currentIndex === 0) return false;

    const currentDate = new Date(this.chatService.sorted_messages[currentIndex].timestamp * 1000);
    const previousDate = new Date(this.chatService.sorted_messages[currentIndex - 1].timestamp * 1000);

    const currentYMD = currentDate.getFullYear().toString() + currentDate.getMonth().toString() + currentDate.getDay().toString();
    const previousYMD = previousDate.getFullYear().toString() + previousDate.getMonth().toString() + previousDate.getDay().toString();

    return currentYMD === previousYMD;
  }

  protected search(): void {

  }

  protected closeReference(): void {
    this.chatService.reference_message = undefined;
  }

  protected onScroll(): void {
    const scrollBottom = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.scrollTop - this.messagesContainer.nativeElement.clientHeight;

    if (scrollBottom >= 300) {
      this.scrollToBottom.nativeElement.classList.add("show");
    } else {
      this.scrollToBottom.nativeElement.classList.remove("show");
    }

    if (this.processing || this.loading || this.unloading || this.max_previous) {
      return;
    }

    this.chatService.current_scroll = () => {};

    const scrollTop = this.messagesContainer.nativeElement.scrollTop;
    if (scrollTop <= 800) {
      this.processing = true;

      const first_message = this.chatService.first_message;
      this.loadPreviousMessages().then(previousMessage => {
        if (!previousMessage) {
          this.processing = false;
          this.max_previous = true;

          return;
        }

        this.scrollToMessage(first_message).then(() => {
          this.processing = false;
        });
      });
    }
  }

  ngAfterViewInit() {
    this.scrollToMessage(this.chatService.last_message, true);
  }

  private async showReferenceMessage(messageId: string) {
    this.closeReference();

    let message = this.chatService.sorted_messages.find(message => message.message_id === messageId);
    if (!message) {
      const results = await db.connection.select({
        from: "messages",
        where: {
          message_id: messageId
        }
      }) as Message[];

      if (results.length) {
        message = results[0] as Message;
      } else {
        Tools.showMessage("Nachricht nicht lokal gefunden", "error");
        // TODO: Load from server
        return;
      }
    }

    this.chatService.reference_message = message;
  }

  ngOnInit(): void {
    if (!this.authService.isDecrypted) {
      return;
    }

    this.subscriptions.push(
      this.chatService.referenceEvent.subscribe(message_id => {
        if (message_id.length) {
          const sup = document.createElement("sup");
          sup.classList.add("reference");
          sup.dataset["message_id"] = message_id;
          sup.contentEditable = "false";
          sup.innerText = "[" + (this.messageInput.nativeElement.querySelectorAll("sup").length + 1) + "]";

          const wrapper = document.createElement("span");
          wrapper.classList.add("wrapper");
          wrapper.contentEditable = "true"; // Make the wrapper contenteditable
          wrapper.appendChild(sup);

          // Create separate span elements for zero-width spaces
          const zwnbspSpan = document.createElement("span");
          zwnbspSpan.innerText = "\uFEFF";

          const zwspSpan = document.createElement("span");
          zwspSpan.innerText = "\u200B";

          const range = window.getSelection()!.getRangeAt(0);
          const selection = window.getSelection()!;

          if (
            range.commonAncestorContainer === this.messageInput.nativeElement ||
            range.commonAncestorContainer.parentNode === this.messageInput.nativeElement
          ) {
            range.insertNode(zwspSpan);  // Insert zero-width space span
            range.insertNode(zwnbspSpan); // Insert zero-width no-break space span
            range.insertNode(wrapper);   // Insert the wrapper span
          } else {
            this.messageInput.nativeElement.insertBefore(zwspSpan, this.messageInput.nativeElement.lastChild);
            this.messageInput.nativeElement.insertBefore(zwnbspSpan, zwspSpan);
            this.messageInput.nativeElement.insertBefore(wrapper, zwnbspSpan);
          }

          range.setStartAfter(zwspSpan);
          range.setEndAfter(zwspSpan);
          selection.removeAllRanges();
          selection.addRange(range);

          if (this.messageInput.nativeElement.lastChild.previousSibling.nodeName !== "BR") {
            this.messageInput.nativeElement.appendChild(document.createElement("br"));
          }

          this.chatService.referenceEvent.next("");
        }
      }),
      this.chatService.referenceClickEvent.subscribe(messageId => {
        if (messageId) {
          this.showReferenceMessage(messageId);

          this.chatService.referenceClickEvent.next("");
        }
      }),
      this.chatService.updateEvent.subscribe(update => {
        if (update) {
          this.scrollToMessage(this.chatService.last_message, true);
          this.chatService.updateEvent.next(false);

          if (Tools.isVisible() && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              command: "hideNotification",
              chat_id: this.chatService.chatId
            });
          }
        }
      }),
      this.route.paramMap.subscribe(paramMap => {
        const chatId = paramMap.get("id")!;

        if (chatId) {
          this.loadChat(chatId);
        }
      }),
    );

    this.visibilityService.addHandler("chat", () => {
      if (Tools.isVisible() && "serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          command: "hideNotification",
          chat_id: this.chatService.chatId
        });
      }
    });

    window.addEventListener("popstate", this.popStateHandler);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.intervals.forEach((interval: any) => clearInterval(interval));
    this.timeouts.forEach((timeout: any) => clearTimeout(timeout));

    if (!this.loading && !this.unloading) {
      this.unloadChat();
    }

    window.removeEventListener("popstate", this.popStateHandler);
  }

  private popStateHandler = (event: PopStateEvent) => {
    if (this.chatService.open_message && event.state.page && event.state.page === "fileviewer") {
      event.preventDefault();

      this.fileViewerService.removeFile(false);
      this.chatService.open_message = undefined;
    } else if (this.chatService.chatId && !this.fileViewerService.hasFile()) {
      event.preventDefault();

      this.unloadChat();
    }
  }

  protected onEnter() {
    if (this.deviceService.isDesktop()) {
      this.enterMessage();
    }
  }

  protected selectFile() {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = () => this.selectedFile(input);

    input.click();
  }

  protected removeFile(index: number) {
    this.selected_files.splice(index, 1);
  }

  protected dropFiles(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      this.selectedFile(event.dataTransfer);
    }
  }

  protected keydownEvent(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.lastChild && target.lastChild.nodeName !== "BR") {
      target.appendChild(document.createElement("br"));
    }

    if (event.key === "Enter") {
      event.preventDefault();

      const range = window.getSelection()!.getRangeAt(0);
      range.deleteContents();

      const br = document.createElement("br");
      range.insertNode(br);

      const div = document.createElement("div");
      range.insertNode(div);

      range.setStartAfter(br);
      range.setEndAfter(br);
      window.getSelection()!.removeAllRanges();
      window.getSelection()!.addRange(range);

      div.scrollIntoView();

      setTimeout(() => {
        div.remove();
      });
    }

    /*if (event.key === "Backspace" || event.which === 151) {
      const range = window.getSelection()!.getRangeAt(0);
      const container = range.commonAncestorContainer as HTMLElement;

      if (container === this.messageInput.nativeElement) {
        const elements = this.messageInput.nativeElement.querySelectorAll(".wrapper");

        if (elements.length) {
          elements[elements.length - 1].remove();
        }
      }

      const previousSibling = container.previousSibling as HTMLElement;
      const previousPreviousSibling = container.previousSibling?.previousSibling as HTMLElement;

      if (
        container.nodeType === 3
        && container.textContent === "\u00A0"
        && previousSibling.classList
        && previousSibling.classList.contains("wrapper")
      ) {
        event.preventDefault();
        previousSibling.remove();
      } else if (
        container.nodeType === 3
        && container.textContent === "\u00A0"
        && previousPreviousSibling.classList
        && previousPreviousSibling.classList.contains("wrapper")
      ) {
        event.preventDefault();
        previousPreviousSibling.remove();
      }
    }*/
  }

  protected pasteEvent(event: ClipboardEvent) {
    if (!event.clipboardData) {
      return
    }

    if (event.clipboardData.files.length) {
      event.preventDefault();
      this.selectedFile(event.clipboardData);
    }
  }

  private async selectedFile(data: HTMLInputElement | DataTransfer) {
    for (let i = 0; i < data.files!.length; i++) {
      const file: File = data.files![i];

      if (this.file_size + file.size > 100000000) {
        Tools.showMessage("Sie können maximal 100 MB an Dateien pro Nachricht senden", "error");
        break;
      }

      this.file_size += file.size;

      const fileInfo: FileInfo = {
        name: file.name,
        type: file.type,
        size: file.size
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        fileInfo.data = event.target!.result as string;

        this.selected_files.push(fileInfo);
        this.scrollToMessage(this.chatService.last_message, true);
      }
      reader.readAsDataURL(file);
    }
  }

  protected async startRecording() {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveform.nativeElement,
      waveColor: "#0d6efd",
      progressColor: "#ffffff",
      height: 25
    });

    try {
      const stream = await Tools.getUserMedia({audio: true});
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
      this.mediaRecorder.onstop = this.handleStop.bind(this);

      this.chunks = [];

      this.mediaRecorder.start(200);
      this.recording = true;
    } catch (error) {
      Tools.showMessage("Kein Zugriff auf das Mikrofon möglich", "error");
    }
  }

  private handleDataAvailable(event: BlobEvent): void {
    this.chunks.push(event.data);
    this.audioType = event.data.type.split(";")[0].split(".")[0];

    this.waveSurfer?.loadBlob(new Blob(this.chunks, {type: event.data.type}));
  }

  private handleStop(): void {
    const audioBlob = new Blob(this.chunks, {type: this.audioType!});

    const fileInfo = {
      name: "recording-" + Tools.current_time + "." + this.audioType!.split("/")[1],
      type: this.audioType!,
      size: audioBlob.size
    } as FileInfo;

    const reader = new FileReader();
    reader.onloadend = (event) => {
      fileInfo.data = event.target!.result as string;

      this.selected_files.push(fileInfo);
      this.scrollToMessage(this.chatService.last_message, true);
    };

    reader.readAsDataURL(audioBlob);

    this.waveSurfer?.empty();
    this.waveSurfer?.destroy();
    this.waveSurfer = null;

    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null;
      this.mediaRecorder.onstop = null;
      this.mediaRecorder = null;
    }
    this.chunks = [];
    this.audioType = null;
  }

  protected stopRecording() {
    if (!this.mediaRecorder) {
      return;
    }

    this.mediaRecorder.stop();
    this.mediaRecorder.stream.getTracks().forEach(track => track.stop())

    this.recording = false;
  }

  protected async enterMessage() {
    const messageInput = this.messageInput.nativeElement;
    const value = messageInput.innerHTML.trim();

    if (value.length || this.selected_files.length) {
      messageInput.innerHTML = "";
      messageInput.focus();

      let encryptedFiles = "";
      if (this.selected_files.length > 0) {
        encryptedFiles = await CryptUtils.encryptData(JSON.stringify(this.selected_files), this.chatService.secret_key!);

        this.selected_files = [];
        this.file_size = 0;
      }

      const data = await CryptUtils.encryptData(value, this.chatService.secret_key!);
      const message_id = Tools.generateUUID();
      const message = {
        message_id: message_id,
        sender_id: this.authService.getUserId()!,
        chat_id: this.chatService.chatId,
        data: data,
        files: encryptedFiles,
        signature: await CryptUtils.signData(data, this.authService.getSignPrivateKey()!),
        timestamp: Tools.current_time,
        open_timestamp: Tools.current_time,
        timer: Number(this.member!.chat!.settings.timer),
        status: MessageStatus.PENDING,
        seen: true
      } as Message;

      this.chatService.sendMessage(message);
      this.scrollToMessage(message, true);

      this.chatService.unsent_messages[this.chatService.chatId] = {};
    }
  }

  async loadChat(chatId: string) {
    if (this.chatService.chatId && this.chatService.chatId !== chatId) {
      this.unloadChat();
    }

    if (!chatId || this.loading || this.unloading) {
      return;
    }

    this.loading = true;
    this.chatService.chatId = chatId;

    const member = await this.homeService.getMember(chatId);
    const secret_entry = await this.getSecretKey(chatId);
    const blocked_entry = ((await db.connection.select({
      from: "blocked_users",
      where: {
        block_id: member.user!.user_id!
      }
    }))[0] ?? {}) as BlockedUser;

    if (blocked_entry.block_id) {
      this.blocked = true;
      this.chatService.blockedState(true);
    }

    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        command: "hideNotification",
        chat_id: chatId
      });
    }

    this.member = member;

    this.chatService.secret_key = await CryptUtils.decryptSecretKeySymmetric(secret_entry.secret_key, this.authService.getSecretKey()!) as CryptoKey;
    this.chatService.sign_public_key = await CryptUtils.importSignPublicKey(member.user!.sign_public_key as string);

    if (!this.loading) {
      return;
    }

    const messageInput = this.messageInput.nativeElement;

    if (typeof this.chatService.unsent_messages[chatId] !== "undefined") {
      messageInput.innerHTML = this.chatService.unsent_messages[chatId].message ?? "";
      this.selected_files = this.chatService.unsent_messages[chatId].files ?? [];
    }

    if (this.chatService.share_data) {
      messageInput.innerText += this.chatService.share_data.title;
      messageInput.innerText += this.chatService.share_data.text;
      messageInput.innerText += this.chatService.share_data.url;

      this.chatService.share_data = null;
    }

    if (!this.loading) {
      return;
    }

    this.loadMessages().then(() => {
      this.scrollToMessage(this.chatService.last_message, true);
      this.loading = false;
      this.max_previous = false;
    });
  }

  private async getSecretKey(chatId: string): Promise<SecretKey> {
    let secret_entry = ((await db.connection.select({
      from: "secret_keys",
      where: {
        chat_id: chatId,
        user_id: this.authService.getUserId()
      }
    }))[0] ?? {}) as SecretKey;

    if (!secret_entry.secret_id) {
      const response = await this.networkService.request("GET", "/chat/" + chatId + "/key");
      if (response.data) {
        secret_entry = response.data as SecretKey;

        if (response.data.type === "rsa") {
          secret_entry.secret_key = await CryptUtils.encryptSecretKeySymmetric(
            await CryptUtils.decryptSecretKey(secret_entry.secret_key as string, this.authService.getPrivateKey() as CryptoKey) as CryptoKey,
            this.authService.getSecretKey() as CryptoKey
          );

          this.networkService.request("POST", "/chat/" + chatId + "/key", JSON.stringify(secret_entry));
        }

        await db.connection.count({
          from: "secret_keys",
          where: {
            secret_id: secret_entry.secret_id!,
            user_id: secret_entry.user_id
          }
        }).then(count => {
          if (count === 0) {
            this.dbHandlerService.addAction(async () => {
              await db.connection.insert({
                into: "secret_keys",
                values: [secret_entry]
              }).catch(e => {
              });
            });
          }
        });
      }
    }

    return secret_entry;
  }

  unloadChat() {
    this.loading = false;
    this.max_previous = false;

    this.unloading = true;

    this.member = undefined;
    this.chatService.secret_key = undefined;
    this.chatService.sign_public_key = undefined;

    this.chatService.unsent_messages[this.chatService.chatId] = {
      message: this.messageInput.nativeElement.innerHTML,
      files: this.selected_files
    };

    this.chatService.messages[this.chatService.chatId] = this.chatService.sorted_messages.slice(-this.message_count);

    this.messageInput.nativeElement.innerHTML = "";
    this.selected_files = [];

    this.chatService.chatId = "";
    this.homeService.badges.next(true);

    this.unloading = false;
  }

  private async loadMessages() {
    if (this.chatService.message_count < this.message_count) {
      let where = {
        chat_id: this.chatService.chatId,
      } as { chat_id: string, timestamp?: any };

      if (this.chatService.first_message) {
        where.timestamp = {
          "<": this.chatService.first_message.timestamp
        };
      }

      const messages = await db.connection.select({
        from: "messages",
        where: where,
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: this.message_count - this.chatService.message_count
      }) as Message[];

      this.chatService.addMessages(messages);
      this.scrollToMessage(this.chatService.last_message, true);
    }

    this.loadNextMessages().then(nextMessage => {
      if (nextMessage) {
        this.homeService.badges.next(true);
        this.scrollToMessage(this.chatService.last_message, true);
      }
    });
  }

  private async loadNextMessages(): Promise<boolean> {
    if (!this.chatService.chatId || this.unloading) {
      return false;
    }

    let where = {
      chat_id: this.chatService.chatId
    } as { chat_id: string, timestamp?: any };

    if (this.chatService.last_message) {
      where.timestamp = {
        ">": this.chatService.last_message.timestamp
      }
    }

    let nextMessage = false;
    let messages = await db.connection.select({
      from: "messages",
      where: where
    }) as Message[];

    if (messages.length) {
      nextMessage = true;

      this.chatService.addMessages(messages);
    }

    if (this.unloading) {
      return false;
    }

    let response: Response;
    if (!messages.length) {
      messages = await db.connection.select({
        from: "messages",
        where: {
          chat_id: this.chatService.chatId,
        },
        order: {
          by: "timestamp",
          type: "desc"
        },
        limit: 1
      });
    }

    if (this.unloading || !this.chatService.chatId) {
      return false;
    }

    if (!messages.length) {
      response = await this.networkService.request("GET", "/chat/" + this.chatService.chatId + "/messages");
    } else {
      const lastMessage = messages[0] as Message;
      response = await this.networkService.request("GET", "/chat/" + this.chatService.chatId + "/messages/" + lastMessage.message_id + "/next");
    }

    const processed = await this.processResponse(response);
    return nextMessage || processed;
  }

  private async loadPreviousMessages(): Promise<boolean> {
    let messages = await db.connection.select({
      from: "messages",
      where: {
        chat_id: this.chatService.chatId,
        timestamp: {
          "<": this.chatService.first_message.timestamp
        }
      },
      order: {
        by: "timestamp",
        type: "desc"
      },
      limit: this.message_count
    }) as Message[];

    if (this.unloading || !this.chatService.chatId) {
      return false;
    }

    if (!messages.length) {
      const response = await this.networkService.request("GET", "/chat/" + this.chatService.chatId + "/messages/" + this.chatService.first_message.message_id + "/previous");
      return await this.processResponse(response);
    } else {
      this.chatService.addMessages(messages);
      return true;
    }
  }

  private async processResponse(response: Response): Promise<boolean> {
    if (response.data) {
      for (let message of response.data as Message[]) {
        const member = await this.homeService.getMember(message.chat_id);
        this.chatService.handleMessage(message, member);
      }

      this.chatService.addMessages(response.data);

      return true;
    }

    return false;
  }

  blockUser() {
    Swal.fire({
      title: "Sind Sie sicher?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Blockieren",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const showError = () => {
          Swal.fire({
            title: "Fehler",
            text: "Beim blockieren vom Benutzer ist ein Fehler aufgetreten",
            icon: "error"
          });
        }

        const blockedUser: BlockedUser = {block_id: this.member!.user_id!};
        this.networkService.request("POST", "/user/block", JSON.stringify(blockedUser)).then(async response => {
          if (response.data) {
            blockedUser.entry_id = response.data.entry_id;

            await db.connection.insert({
              into: "blocked_users",
              values: [blockedUser]
            }).catch(() => showError());

            this.blocked = true;
            this.chatService.blockedState(true);
          } else {
            showError();
          }
        });
      }
    });
  }

  unblockUser() {
    db.connection.select({
      from: "blocked_users",
      where: {
        block_id: this.member!.user_id!
      }
    }).then((results) => {
      if (results.length) {
        const blockedUser = results[0] as BlockedUser;
        this.networkService.request("DELETE", "/user/block/" + blockedUser.block_id).then(async () => {
          await db.connection.remove({
            from: "blocked_users",
            where: {
              block_id: blockedUser.block_id
            }
          }).catch(e => {
          });

          this.blocked = false;
          this.chatService.blockedState(false);
        });
      }
    });

  }

  protected scrollToMessage(message: Message, end: boolean = false, timeout: number = 0): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!message || !this.messagesContainer) {
        resolve(false);

        return;
      }

      this.chatService.current_scroll = () => {
        const scrollableDivEl = this.messagesContainer.nativeElement;

        let tries = 0;
        const message_interval = setInterval(() => {
          if (!message) {
            clearInterval(message_interval);
            resolve(false);

            return;
          }

          const messageElementEl = scrollableDivEl.querySelector(`[data-id='${message.message_id}']`);

          if (!messageElementEl) {
            if (tries < 10) {
              tries++;
            } else {
              clearInterval(message_interval);
              resolve(false);
            }

            return;
          }

          clearInterval(message_interval);
          setTimeout(() => {
            let scroll;

            if (messageElementEl) {
              scroll = messageElementEl.offsetTop;

              if (end) {
                scroll += messageElementEl.offsetHeight;
              }
            } else {
              scroll = scrollableDivEl.scrollHeight;
            }

            scrollableDivEl.scrollTop = scroll - scrollableDivEl.offsetTop;

            resolve(true);
            return;
          }, timeout);
        }, 50);
      }

      this.chatService.current_scroll();
    });
  }

  setMessageTime(): void {
    const options = {
      title: "Nachrichten Ablaufzeit anpassen",
      text: "Bitte beachten Sie, dass die Nachrichten Ablaufzeit auch für alle anderen Teilnehmer dieser Unterhaltung angepasst wird",
      input: "select",
      inputOptions: {
        0: "Keine Ablaufzeit",
        300: "5 Minuten",
        600: "10 Minuten",
        1800: "30 Minuten",
        3600: "1 Stunde",
        18000: "5 Stunden",
        36000: "10 Stunden",
        43200: "12 Stunden",
        86400: "24 Stunden",
      },
      inputValue: Number(this.member!.chat!.settings.timer),
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Ändern",
      cancelButtonText: "Abbrechen",
      showLoaderOnConfirm: true,
      didOpen: () => {
        const firstOption = document.querySelector(".swal2-select option:first-of-type")! as HTMLOptionElement;
        firstOption.setAttribute("disabled", "true");
        firstOption.value = "";
      },
      preConfirm: async (timer: number) => {
        const response = await this.networkService.request("POST", "/chat/" + this.chatService.chatId + "/settings/time", JSON.stringify({"timer": timer}));
        if (response.status === "success") {
          Swal.fire({
            title: "Erfolgreich",
            text: response.message,
            icon: "success"
          });

          this.member!.chat!.settings.timer = timer;

          await db.connection.update({
            in: "chats",
            set: {
              settings: JSON.stringify(this.member!.chat!.settings)
            },
            where: {
              chat_id: this.chatService.chatId
            }
          }).catch(e => {
          });

          const message = {
            message_id: Tools.generateUUID(),
            sender_id: "system",
            chat_id: this.chatService.chatId,
            data: "Sie haben den Timer auf " + new FormatTimePipe().transform(timer) + " geändert",
            files: "",
            timestamp: Math.round(Date.now() / 1000),
            timer: Number(timer)
          } as Message;

          this.chatService.handleMessage(message, this.member!);
          this.chatService.addMessage(message);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    } as SweetAlertOptions;

    if (this.member!.chat!.settings.timer === 0) {
      options.footer = "WARNUNG: Diese Unterhaltung hat zurzeit eine unendliche Ablaufzeit. Eine unendliche Ablaufzeit ist danach nicht mehr möglich!";
    }

    Swal.fire(options);
  }

  protected readonly FileUtils = FileUtils;
}
