import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: "[swipe]" })
export class SwipeDirective {

  @Output() right = new EventEmitter<void>();
  @Output() left = new EventEmitter<void>();
  @Output() up = new EventEmitter<void>();
  @Output() down = new EventEmitter<void>();

  private swipeCoord = [0, 0];
  private swipeTime = new Date().getTime();

  // Mapping directions to their corresponding EventEmitter properties
  private directionsMap = {
    right: this.right,
    left: this.left,
    up: this.up,
    down: this.down,
  } as any;

  constructor() { }

  @HostListener('touchstart', ['$event']) onSwipeStart(event: TouchEvent) {
    this.onSwipe(event, 'start');
  }

  @HostListener('touchend', ['$event']) onSwipeEnd(event: TouchEvent) {
    this.onSwipe(event, 'end');
  }

  private onSwipe(event: TouchEvent, when: string): void {
    const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000) {
        this.determineSwipeDirection(event, direction, duration);
      }
    }
  }

  private determineSwipeDirection(event: TouchEvent, direction: number[], duration: number): void {
    const [x, y] = direction;
    if (Math.abs(x) > 30 && Math.abs(x) > Math.abs(y * 3)) {
      this.emitSwipeDirection(event, x < 0 ? 'right' : 'left');
    } else if (Math.abs(y) > 30 && Math.abs(y) > Math.abs(x * 3)) {
      this.emitSwipeDirection(event, y < 0 ? 'up' : 'down');
    } else if (duration < 500) {
      event.target!.dispatchEvent(new Event("click"));
    }
  }

  private emitSwipeDirection(event: TouchEvent, direction: string): void {
    const eventEmitter = this.directionsMap[direction];
    if (eventEmitter) {
      event.preventDefault();
      eventEmitter.emit();
    }
  }
}
