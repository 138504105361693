<ng-container *ngIf="this.message.sender_id !== 'system' else systemMessage">
  <div [attr.data-id]="this.message.message_id"
       [class.visible]="this.processed"
       [ngClass]="{'left': this.message.sender_id !== authService.getUserId(), 'right' : this.message.sender_id === authService.getUserId() }"
       class="message">
    <div class="content"
         [ngClass]="{'cursor-pointer': this.message.status === MessageStatus.ENCRYPTED}"
         (click)="this.message.status === MessageStatus.ENCRYPTED ? processMessage(message, true) : ''"
         (contextmenu)="contextMenu.openContextMenu($event)">
      <div #referencesContainer class="references" swipe
           (left)="this.showItem(this.referencesIndex - 1)"
           (right)="this.showItem(this.referencesIndex + 1)"
           [class.visible]="this.references.length"
           [class.single]="this.references.length <= 1">
        @for (reference of this.references; track reference; let index = $index) {
          <div class="reference" [class.active]="index === 0" [attr.data-message_id]="reference.message_id" (click)="this.showMessage($event)">
            <div class="username"><span>{{ reference.username + (reference.username === this.authService.getUsername() ? " (Sie)" : "") }}</span></div>
            <span class="text">{{ reference.data }}</span>
          </div>
        }

        <!-- Pagination Dots -->
        <div class="pagination-dots" *ngIf="this.references.length > 1">
          @for (reference of this.references; track reference; let index = $index) {
            <span class="dot" [class.active]="index === this.referencesIndex"
                  (click)="this.showItem(index); $event.stopPropagation()"></span>
          }
        </div>
      </div>
      <div swipe
        (left)="this.chatService.referenceEvent.next(this.message.message_id!)"
        (touchstart)="this.contextMenu.handleTouchStart($event)"
        (touchmove)="this.contextMenu.handleTouchMove()"
        (touchend)="this.contextMenu.handleTouchEnd($event)">
        <div *ngIf="this.message.status === MessageStatus.INVALID_SIGNATURE" class="alert alert-danger text mb-2"
             translate="no">Die Signatur hat sich verändert!
        </div>
        <ng-container *ngIf="this.message.status !== MessageStatus.ENCRYPTED else encryptedMessage">
          <ng-container *ngIf="isArray(this.message.files) && this.message.files.length">
            <div class="images">
              @for (file of this.message.files; track file; let index = $index) {
                <ng-container *ngIf="FileUtils.image_types.includes(file.type)">
                  <ng-container *ngIf="file.size < 5000000 else bigImage">
                    <img (click)="openFile(index)" alt="Image" src="{{ file.data | checkXSS }}"
                         (load)="this.chatService.current_scroll()">
                  </ng-container>
                  <ng-template #bigImage>
                    <div class="image" (click)="this.openFile(index)">
                      <img alt="Image" src="{{FileUtils.icon_list[file.type]}}"
                           (load)="this.chatService.current_scroll()">
                    </div>
                  </ng-template>
                </ng-container>
              }
            </div>
            <div class="videos">
              @for (file of this.message.files; track file; let index = $index) {
                <ng-container *ngIf="FileUtils.video_types.includes(file.type)">
                  <ng-container *ngIf="file.size < 5000000 else bigVideo">
                    <video controls>
                      <source src="{{ file.data }}" type="{{ file.type | checkXSS }}"
                              (load)="this.chatService.current_scroll()">
                    </video>
                  </ng-container>
                  <ng-template #bigVideo>
                    <div class="video" (click)="this.openFile(index)">
                      <img alt="Video" src="{{FileUtils.icon_list[file.type]}}"
                           (load)="this.chatService.current_scroll()">
                    </div>
                  </ng-template>
                </ng-container>
              }
            </div>
            <div class="audios">
              @for (file of this.message.files; track file; let index = $index) {
                <ng-container *ngIf="FileUtils.audio_types.includes(file.type)">
                  <div class="audio" (click)="this.openFile(index)">
                    <img alt="Audio" src="{{FileUtils.icon_list[file.type]}}"
                         (load)="this.chatService.current_scroll()">
                  </div>
                </ng-container>
              }
            </div>
            <div class="documents">
              @for (file of this.message.files; track file; let index = $index) {
                <ng-container *ngIf="FileUtils.document_types.includes(file.type)">
                  <div class="document" (click)="this.downloadFile(index)">
                    <img alt="Document" src="{{FileUtils.icon_list[file.type]}}"
                         (load)="this.chatService.current_scroll()">
                    <span class="d-block">{{ file.name }}</span>
                  </div>
                </ng-container>
              }
            </div>
            <div class="files">
              @for (file of this.message.files; track file; let index = $index) {
                <ng-container *ngIf="!FileUtils.all_types.includes(file.type)">
                  <div class="file" (click)="this.downloadFile(index)">
                    <img alt="File" src="{{FileUtils.icon_list['file']}}"
                         (load)="this.chatService.current_scroll()">
                    <span class="d-block">{{ file.name }}</span>
                  </div>
                </ng-container>
              }
            </div>
          </ng-container>
          <div #decryptedMessage *ngIf="this.message.data !== ''" [innerHTML]="this.message.data | bypassSecurity"
               class="text mb-2" translate="no"></div>
        </ng-container>
        <ng-template #encryptedMessage>
          <div class="text mb-2" translate="no">Hier drücken um Nachricht zu entschlüsseln</div>
        </ng-template>
      </div>
      <div class="d-flex">
        <div class="time me-3">
          <ng-container *ngIf="this.message.countdown">{{ this.message.countdown | formatTime }} -</ng-container>
          {{ this.message.timestamp * 1000 | date: "HH:mm" }}
        </div>
        <div *ngIf="this.message.sender_id === authService.getUserId()"
             [innerHTML]="this.message.status | getStatus"
             class="status-icon ms-3"></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #systemMessage>
  <div class="system-message">
    <div>{{ this.message.data }}</div>
  </div>
</ng-template>

<app-contextmenu #contextMenu [menuItems]="contextMenuItems"
                 (actionSelected)="onContextMenuAction($event)"></app-contextmenu>
