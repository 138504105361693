import {Component} from '@angular/core';
import {AuthComponent} from "../auth.component";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent extends AuthComponent {

}
