import {Injectable} from "@angular/core";
import {FileInfo} from "../model/FileInfo";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class FileViewerService {
  protected file?: FileInfo;

  accepted = new BehaviorSubject<boolean>(false);
  controls: boolean = false;

  constructor() {
  }

  setFile(file: FileInfo) {
    if (typeof this.file === "undefined") {
      window.history.pushState({page: "fileviewer"}, "");
      window.history.pushState(null, "");
    }

    this.file = file;
  }

  getFile(): FileInfo|undefined {
    return this.file;
  }

  hasFile(): boolean {
    return typeof this.file !== "undefined";
  }

  removeFile(history = true) {
    if (history) {
      window.history.back();
    }

    this.file = undefined;
  }
}
