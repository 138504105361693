import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Message, MessageStatus} from "../model/Message";
import {AuthService} from "./auth";
import {Tools} from "../tools";
import {NetworkService} from "./network";
import {db} from "../../../db/db";
import {Member} from "../model/Member";
import {HomeService} from "./home";
import {CryptUtils} from "../crypt_utils";
import {DBHandlerService} from "./db_handler";

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  chatId = "";

  updateEvent = new BehaviorSubject<boolean>(false);

  referenceEvent = new BehaviorSubject<string>("");
  referenceClickEvent = new BehaviorSubject<string>("");

  reference_message?: Message;
  references_messages: { [message_id: string]: any[] } = {};

  open_message?: Message;
  messages: { [chat_id: string]: Message[] } = {};
  unsent_messages: { [chat_id: string]: any } = {};

  secret_key?: CryptoKey;
  sign_public_key?: CryptoKey;

  share_data: any = null;
  current_scroll: any = () => {};

  constructor(private dbHandlerService: DBHandlerService, private authService: AuthService, private networkService: NetworkService, private homeService: HomeService) {
  }

  async createChat(user_id: string, user_secret_key: object, creator_secret_key: object, chat_id: string = "", settings: any = {timer: 0}) {
    const request = {
      chat_id: chat_id,
      user_id: user_id,
      secret_keys: [
        {
          data: user_secret_key,
          user_id: user_id,
        },
        {
          data: creator_secret_key,
          user_id: this.authService.getUserId(),
        }
      ],
      is_group: false,
      settings: settings
    };

    return this.networkService.request("POST", "/chat", JSON.stringify(request));
  }

  private async recreateChat(chat_id: string): Promise<void> {
    const member = await this.homeService.getMember(chat_id);

    const user_public_key = await CryptUtils.getPublicKey(member.user!.public_key as string);
    const user_secret_key = {
      key: await CryptUtils.encryptSecretKey(this.secret_key!, user_public_key),
      type: "rsa"
    };
    const creator_secret_key = {
      key: await CryptUtils.encryptSecretKeySymmetric(this.secret_key!, this.authService.getSecretKey() as CryptoKey),
      type: "aes"
    };

    await this.createChat(member.user!.user_id, user_secret_key, creator_secret_key, this.chatId, member.chat!.settings);

  }

  async sendMessage(message: Message, retry: boolean = false): Promise<void> {
    this.addMessage(message);

    const response = await this.networkService.request("POST", "/message", JSON.stringify(message));
    this.removeMessage(message, false);

    if (response.data) {
      message.message_id = response.data.message_id;
      message.status = MessageStatus.RECEIVED;
    } else if (response.code === 404 && !retry) {
      await this.recreateChat(message.chat_id);
      await this.sendMessage(message, true);
      return;
    } else if (response.code === 0 && !Tools.isVisible()) {
      return;
    } else {
      message.status = MessageStatus.ERROR;
    }

    this.insertMessage(message);

    this.homeService.updateTimestamp(await this.homeService.getMember(message.chat_id), message.timestamp);
  }

  async sentMessages(messages: Message[]): Promise<void> {
      for (const message of messages) {
        this.removeMessage(message);
      }

      for (const message of messages) {
        message.status = MessageStatus.RECEIVED;

        this.insertMessage(message);

        this.homeService.updateTimestamp(await this.homeService.getMember(message.chat_id), message.timestamp);
      }
  }

  handleMessage(message: Message, member: Member): void {
    if (message.sender_id === this.authService.getUserId()) {
      message.status = MessageStatus.RECEIVED;
      message.open_timestamp = Tools.current_time;
      message.seen = true;
    } else if ((member.chat!.settings.click_to_open || this.authService.getSettings().click_to_open) && message.sender_id !== "system") {
      message.status = MessageStatus.ENCRYPTED;
      message.seen = false;
    } else if (message.chat_id === this.chatId) {
      message.status = MessageStatus.RECEIVED;
      message.open_timestamp = Tools.current_time;
      message.seen = true;
    } else {
      message.status = MessageStatus.RECEIVED;
      message.seen = false;
    }

    if (member && (typeof member.timestamp === "undefined" || member.timestamp < message.timestamp)) {
        this.homeService.updateTimestamp(member, message.timestamp);
    }

    this.dbHandlerService.addAction(async () => {
      await db.connection.remove({
        from: "messages",
        where: {
          message_id: message.message_id!
        }
      }).catch(e => {
      });
    });

    this.dbHandlerService.addAction(async () => {
      await db.connection.insert({
        into: "messages",
        values: [message],
      }).catch(e => {
      });
    });

    if (message.sender_id !== this.authService.getUserId()) {
      return;
    }

    this.dbHandlerService.addAction(async () => {
      await db.connection.update({
        in: "messages",
        set: {
          seen: true
        },
        where: {
          chat_id: message.chat_id,
          timestamp: {
            "<": message.timestamp
          }
        }
      }).catch(e => {
      });
    });
  }

  async handleMessages(messages: Message[]): Promise<void> {
    for (const message of messages.sort( (a, b) => a.timestamp - b.timestamp)) {
      const member = await this.homeService.getMember(message.chat_id);
      this.handleMessage(message, member);
    }
  }

  addMessage(message: Message): boolean {
    if (typeof this.messages[message.chat_id] === "undefined") {
      this.messages[message.chat_id] = [];
    }

    if (typeof this.references_messages[message.message_id!] === "undefined") {
      this.references_messages[message.message_id!] = [];
    }

    if (this.messages[message.chat_id].findIndex(m => m.message_id === message.message_id) !== -1) {
      return false;
    }

    this.messages[message.chat_id].push(Object.assign({}, message));

    if (message.chat_id === this.chatId) {
      this.updateEvent.next(true);
    }

    return true;
  }

  addMessages(messages: Message[]): void {
    messages.map(message => this.addMessage(message));
  }

  insertMessage(message: Message): void {
    this.addMessage(message);

    this.dbHandlerService.addAction(async () => {
      await db.connection.remove({
        from: "messages",
        where: {
          message_id: message.message_id!
        }
      }).catch(e => {
      });
    });

    this.dbHandlerService.addAction(async () => {
      await db.connection.insert({
        into: "messages",
        values: [message],
      }).catch(e => {
      });
    });
  }

  removeMessage(message: Message, database: boolean = true): void {
    if (database) {
      this.dbHandlerService.addAction(async () => {
        await db.connection.remove({
          from: "messages",
          where: {
            message_id: message.message_id!
          }
        }).catch(e => {
        });
      });
    }

    const index = this.messages[message.chat_id].findIndex(m => m.message_id === message.message_id);
    if (index === -1) {
      return;
    }

    this.messages[message.chat_id].splice(index, 1);
  }

  get sorted_messages(): Message[] {
    if (this.chatId === "" || !this.messages[this.chatId]) {
      return [];
    }

    return [...this.messages[this.chatId]].sort((a, b) => a.timestamp - b.timestamp).filter(m => m.status !== MessageStatus.DELETED);
  }

  get message_count(): number {
    return this.sorted_messages.length;
  }

  get first_message(): Message {
    return this.sorted_messages[0] ?? null;
  }

  get last_message(): Message {
    return this.sorted_messages[this.sorted_messages.length - 1] ?? null;
  }
}
