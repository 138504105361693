import Swal, {SweetAlertIcon} from "sweetalert2";
import {FormControl} from "@angular/forms";

import {FileInfo} from "./model/FileInfo";

export type Response = {
  message: string,
  status: string,
  code: number,
  data: any,
  error: any
};

export class Tools {
  static pageName: string = "tunnl";
  static updating: boolean = false;

  static asFileInfo(data: any): FileInfo {
    return data as FileInfo;
  }

  static convertStringsToBooleans(obj: any) {
    const clonedObj = structuredClone(obj);

    if (typeof clonedObj === "object" && clonedObj !== null) {
      Object.keys(clonedObj).forEach(key => {
        const value = clonedObj[key];
        if (value === true || value === false || value === "true" || value === "false") {
          clonedObj[key] = value === "true" || value === true;
        } else if (typeof value === "object" && value !== null) {
          clonedObj[key] = this.convertStringsToBooleans(value);
        }
      });
    }

    return clonedObj;
  }

  static isVisible(): boolean {
    if (typeof document.hidden !== "undefined") {
      return !document.hidden;
    } else if (typeof document.visibilityState !== "undefined") {
      return document.visibilityState === "visible";
    } else {
      return true;
    }
  }

  /**
   * Schaltet das Anzeigen des Passworts um, wenn der dazugehörige Knopf angeklickt wird.
   * @param {Event} event Das auslösende Ereignis.
   * @return {void}
   */
  static togglePassword(event: Event) {
    let button = event.currentTarget as HTMLButtonElement;
    let icon = button.querySelector("i") as HTMLImageElement;
    let input = this.previousElementSibling(button, "input") as HTMLInputElement;

    if (input.type === "password") {
      input.type = "text";
      input.placeholder = "SehrSicher1337!!";
      icon.classList.remove("bi-eye");
      icon.classList.add("bi-eye-slash");
    } else {
      input.type = "password";
      input.placeholder = "***********";
      icon.classList.remove("bi-eye-slash");
      icon.classList.add("bi-eye");
    }
  }

  /**
   * Gibt das vorherige Element im DOM zurück, das dem gegebenen Selektor entspricht.
   * Wenn kein passendes Element gefunden wird, wird null zurückgegeben.
   * @param {HTMLElement} element Das Ausgangselement.
   * @param {string} selector Der Selektor des gesuchten Elements.
   * @return {HTMLElement|null} Das gefundene Element oder null.
   */
  static previousElementSibling(element: HTMLElement, selector: string): HTMLElement | null {
    let previousSibling = element.previousElementSibling;

    if (previousSibling !== null) {
      if (!previousSibling.matches(selector))
        previousSibling = this.previousElementSibling(previousSibling as HTMLElement, selector);

      return previousSibling as HTMLElement;
    }
    return null;
  }

  /**
   * Zeigt eine Benachrichtigung auf dem Bildschirm an
   * @param {string} message Die Nachricht, die angezeigt werden soll
   * @param {SweetAlertIcon | string} type Das Symbol, das in der Benachrichtigung angezeigt werden soll
   */
  static showMessage(message: string, type: SweetAlertIcon | string): void {
    Swal.fire({
      title: message,
      icon: type as SweetAlertIcon,
      position: "top-end",
      timer: 2000,
      toast: true,
      showConfirmButton: false,
      timerProgressBar: true
    });
  }

  /**
   * Überprüft das FormControl-Objekt auf Gültigkeit.
   * @param {FormControl<string | null>} control Das FormControl-Objekt, das auf Gültigkeit überprüft werden soll.
   * @param {string} error Der optionale Fehler, der überprüft werden soll.
   * @return {boolean} Gibt true zurück, wenn das FormControl-Objekt ungültig ist oder der übergebene Fehler vorliegt. Gibt false zurück, wenn das FormControl-Objekt gültig ist und kein Fehler vorliegt.
   */
  static checkFormControl(control: FormControl<string | null>, error?: string): boolean {
    if (control.dirty || control.touched) {
      if (typeof error !== "undefined") {
        return control.hasError(error);
      }
      return control.invalid;
    }
    return false;
  }

  static generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static get current_time(): number {
    return Math.round(Date.now() / 1000);
  }

  static stripHtml(html: string): string {
    const tmp = document.createElement("DIV");

    tmp.innerHTML = html.replaceAll(/(<br\s*\/?>)+/gi, " ");
    tmp.querySelectorAll("sup").forEach(item => item.remove());

    return tmp.textContent || tmp.innerText || "";
  }

  static async getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints);
    } else {
      const anyNavigator = navigator as any;

      const getUserMedia = anyNavigator.getUserMedia
        || anyNavigator.webkitGetUserMedia
        || anyNavigator.mozGetUserMedia
        || anyNavigator.msGetUserMedia;

      if (getUserMedia) {
        return new Promise<MediaStream>((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      } else {
        throw new Error("getUserMedia is not supported in this browser.");
      }
    }
  }
}
