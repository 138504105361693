<ng-container *ngIf="this.member.chat!.picture; else userPicture">
  <img alt="{{this.member.chat!.name}}" class="img-cover"
       [src]="Tools.asFileInfo(this.member.chat!.picture).data! | checkXSS">
</ng-container>
<ng-template #userPicture>
  <ng-container *ngIf="this.member.user!.picture; else defaultPicture">
    <img alt="{{this.member.user!.username}}" class="img-cover"
         [src]="Tools.asFileInfo(this.member.user!.picture).data! | checkXSS">
  </ng-container>
  <ng-template #defaultPicture>
    <img alt="{{this.member.user!.username}}" class="img-cover" src="assets/img/default-light.png">
  </ng-template>
</ng-template>
