import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../assets/js/service/auth";
import {Tools} from "../../../assets/js/tools";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  formGroup = new FormGroup({
    username: new FormControl("", [
      Validators.required,
      Validators.minLength(4)
    ]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(14)
    ])
  });
  protected readonly Tools = Tools;

  constructor(private authService: AuthService) {
  }

  login() {
    this.authService.login(this.formGroup);
  }
}
