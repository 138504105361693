<div #sidebar class="settings-sidebar" [class.show]="this.open_state">
  <i class="bi bi-x close" (click)="this.close()"></i>

  <div class="py-5 px-4">
    <div class="picture d-flex w-100 mt-3" (click)="this.selectImage()">
      <ng-container *ngIf="this.image && this.image.data else defaultPicture">
        <img alt="{{this.authService.getUsername()}}" class="img-cover" src="{{this.image.data}}">
      </ng-container>
      <ng-template #defaultPicture>
        <img alt="{{this.authService.getUsername()}}" class="img-cover" src="assets/img/default-light.png">
      </ng-template>
    </div>
    <div class="username d-flex flex-column w-100 mt-4">
      <form class="m-auto d-flex w-100" (ngSubmit)="this.submitUsername()" [formGroup]="userFormGroup">
        <input #username tabindex="1" autocomplete="username" class="form-control" formControlName="username"
               id="username" placeholder="Kevin" type="text" readonly/>
        <i *ngIf="!this.edit_mode" class="bi bi-pencil ms-3 p-2" (click)="this.editMode()"></i>

        <button *ngIf="this.edit_mode" class="bi bi-check ms-3 p-2" type="submit"></button>
        <button *ngIf="this.edit_mode" class="bi bi-x p-2" (click)="this.exitMode()"></button>
      </form>
      <span *ngIf="Tools.checkFormControl(userFormGroup.controls.username, 'minlength')" class="error d-block"> muss mindestens {{ userFormGroup.controls.username.getError('minlength')['requiredLength'] }}
        Zeichen lang sein</span>
      <span *ngIf="Tools.checkFormControl(userFormGroup.controls.username, 'maxlength')" class="error d-block"> darf maximal {{ userFormGroup.controls.username.getError('maxlength')['requiredLength'] }}
        Zeichen lang sein</span>
    </div>
    <hr class="mt-4"/>
    <div class="extra d-flex flex-column w-100 mt-3">
      <form class="d-flex flex-column w-100 disabled" (ngSubmit)="this.changePassword()" [formGroup]="passwordFormGroup">
        <div class="d-flex flex-column">
          <label class="flex-grow-1" for="password">Neues Passwort</label>
          <div class="d-flex">
            <input tabindex="4" [ngClass]="{'error': Tools.checkFormControl(passwordFormGroup.controls.password)}" autocomplete="new-password" class="form-control d-inline" formControlName="password" id="password" placeholder="***********" type="password">
            <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
          </div>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password, 'required')" class="error d-block"> wurde nicht ausgefüllt</span>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password, 'contains')" class="error d-block"> darf den Vor- und Nachnamen nicht enthalten</span>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password, 'minlength')" class="error d-block"> muss mindestens {{passwordFormGroup.controls.password.getError('minlength')['requiredLength']}} Zeichen lang sein</span>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password, 'password')" class="error d-block"> entspricht nicht den Kennwortrichtlinien</span>
        </div>
        <div class="d-flex flex-column mt-1">
          <label class="flex-grow-1" for="password_repeat">Passwort wiederholen</label>
          <div class="d-flex">
            <input tabindex="5" [ngClass]="{'error': Tools.checkFormControl(passwordFormGroup.controls.password_repeat)}" autocomplete="new-password" class="form-control d-inline" formControlName="password_repeat" id="password_repeat" placeholder="***********"
                   type="password">
            <button (click)="Tools.togglePassword($event)" class="btn in-input" tabindex="-1" type="button"><i class="bi bi-eye"></i></button>
          </div>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password_repeat, 'required')" class="error d-block"> wurde nicht ausgefüllt</span>
          <span *ngIf="Tools.checkFormControl(passwordFormGroup.controls.password_repeat, 'matching')" class="error d-block"> passwörter stimmen nicht überein</span>
        </div>
        <button class="btn btn-primary mt-3" type="submit">Passwort ändern</button>
      </form>
      <button class="btn btn-secondary mt-4" (click)="this.authService.logout()">Abmelden</button>
      <button class="btn btn-danger mt-4" (click)="this.deleteAccount()">Konto löschen</button>
    </div>
    <hr class="mt-4"/>
    <h3>Einstellungen</h3>
    <div class="settings d-flex w-100 mt-2">
      <form class="d-flex flex-column w-100" (ngSubmit)="this.submitSettings()" [formGroup]="settingsFormGroup">
        <div class="d-flex">
          <label class="flex-grow-1" for="click_to_open">Klicken um Nachrichten zu entschlüsseln</label>
          <input tabindex="2" autocomplete="click_to_open" class="form-check-input" formControlName="click_to_open"
                 id="click_to_open"
                 type="checkbox" checked/>
        </div>
        <div class="d-flex mt-1">
          <label class="flex-grow-1" for="dark_mode">Dunkler Modus</label>
          <input tabindex="3" autocomplete="dark_mode" class="form-check-input" formControlName="dark_mode"
                 id="dark_mode"
                 type="checkbox" checked/>
        </div>
        <button class="btn btn-primary mt-4" type="submit">Speichern</button>
      </form>
    </div>
    <hr class="mt-4"/>
    <div class="diverse d-flex flex-column w-100 mt-2">
      <div class="alert alert-dark mt-2" role="alert">
        <b>Achtung!</b> Die Daten auf dem Server werden nicht gelöscht. <br/>
        Sie werden von allen Diensten abgemeldet und die lokalen Daten werden gelöscht. <br/>

        Die lokalen Daten beinhalten: <br/>
        <ul>
          <li>Chats</li>
          <li>Einstellungen</li>
          <li>Cookies</li>
          <li>Dateien</li>
        </ul>
      </div>
      <button class="btn btn-danger" (click)="this.clearData()">Selbstzerstörung</button>
    </div>
  </div>
  <app-file-viewer></app-file-viewer>
</div>
