import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {AuthService} from "./service/auth";

/**
 * Ein Angular Guard, der überprüft, ob der Benutzer angemeldet ist.
 * Wenn der Benutzer nicht angemeldet ist, wird er zur Login-Seite weitergeleitet und die Navigation wird blockiert.
 * @param {AuthService} authService, beinhaltet alle Methoden, die für die Authentifizierung benötigt werden
 * @param {Router} router Ein Angular Service, der die Navigation innerhalb der Anwendung ermöglicht
 * @return {boolean} Gibt true zurück, wenn der Benutzer angemeldet ist, andernfalls false
 */
@Injectable({
  providedIn: "root",
})
export class LoginGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    if (!this.authService.isLoggedIn) {
      this.router.navigateByUrl("/auth/login");
      return of(false);
    }
    return of(true);
  }
}

/**
 * Ein Angular Guard, der überprüft, ob der Benutzer ein Administrator ist.
 * Wenn der Benutzer kein Administrator ist, wird er zur Startseite weitergeleitet und die Navigation wird blockiert.
 * @param {AuthService} authService, beinhaltet alle Methoden, die für die Authentifizierung benötigt werden
 * @param {Router} router Die Router-Instanz zur Navigation zu anderen Routen.
 * @return {boolean} Gibt true zurück, wenn der Benutzer ein Administrator ist, andernfalls false
 */
@Injectable({
  providedIn: "root",
})
export class DecryptGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.isDecrypted) {
      this.authService.redirectUrl = state.url;

      this.router.navigateByUrl("/auth/decrypt");
      return of(false);
    }

    return of(true);
  }
}

@Injectable({
  providedIn: "root",
})
export class AppGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    if (this.authService.isDecrypted) {
      this.router.navigateByUrl("/home");
      return of(false);
    }
    return of(true);
  }
}
